import { Input } from "postcss";
import { FC, Fragment, InputHTMLAttributes, ReactNode } from "react";
import InputFrame from "../atoms/InputFrame";

export type OptionProps = {
    value: string;
    label: string;
}

interface SelectTextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    label: string;
    errorMessage?: string;
    onChange: (e: any) => void;
    items: OptionProps[];
    emptyValue?: string;
    emptyLabel?: string;
    prefix?: ReactNode;
}

const SelectTextField: FC<SelectTextFieldProps> = ({ id, name, label,
    errorMessage, items,
    className,
    prefix,
    value, onChange,
    emptyValue = '',
    emptyLabel = 'Selecione um registro...',
    disabled = false }) => {

    const error = errorMessage ? true : false;
    const borderColor = error ? "border-red-600" : "border-gray-300";

    return (

        <InputFrame
            label={label}
            errorMessage={errorMessage}
            className={className}
            prefix={prefix}
        >
            <select id={id}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 ${borderColor} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer disabled:opacity-50 disabled:cursor-not-allowed`}
            >
                <option value={emptyValue}>{emptyLabel}</option>

                {items.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
        </InputFrame>
    );
}

export default SelectTextField;
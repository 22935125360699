import {FaLink } from 'react-icons/fa';
import NewTooltip from '../../atoms/NewTooltip';
import SelectFilter from '../../organisms/SelectFilter';
import PageSearch from '../../molecules/PageSearch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ASearch from '../../organisms/ASearch';
import PageList from '../../templates/PageList';
import { useState } from 'react';

type OptionKey = "btn-approve" | "btn-reject" | "btn-approve-reason" | "btn-reject-reason";


type Referral = {
    id: number,
    referralUUId: string,
    referralId: number,
    referralDate: string, //
    socialId: string,
    name: string, //fullname
    recurrence: boolean, //
    amountLoans: number,
    partnerName: string,
    partnerId: number,
    guarantorName: string,
    guarantorId: number,
    source: string,
    sourceId: number,
    originEnvironment: string,
    guaranteePercentage: number,
    scorePartner: number, // mudar para score Avalista
    scoreAgrolend: number,
    creditLimit: number, //
    creditLimitFormated: string, //

    creditLimitAvailable: number,//lc.disponivel
    creditLimitAvailableFormated: string,//lc.disponivel

    desiredValueApprovedValueDifference: number, //Var $ pedido vs. LC aprovado
    desiredValueApprovedValueDifferenceFormated: string, //Var $ pedido vs. LC aprovado
    recommendationCode: string,
    recommendation: string,
    status: string,
    partnerLimit: number,
    limitCreditDivideCreditRuralInPercentual: string, //
    limitCreditDivideCreditRuralInPercentualStatus: string,//

    limitCreditApprovedDivideCreditRuralInPercentual: string, //
    limitCreditApprovedDivideCreditRuralInPercentualStatus: string,//
    creditRuralPreAnalysis: number,
    creditRuralPreAnalysisFormatedValue: string,

    valueToReceive: number,

    creditRuralFormatedValue: string,
    desiredValue: string, //
    observation: string,
    approvedValue: string,
    approvedValueFormated: string,
    balanceCreditLimitFormated: string,
    daysBetweenPreAnalysisAndOrder: string,
    amountPreAnalysis: string,
    preAnalysisType:string,
    preAnalysisId:string,

    canApprove: boolean,
    canReject: boolean,
    canApproveReason: boolean,
    canRejectReason: boolean

}

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/loan-order-analysis`;

const FarmerOrderUnlinkedListPage = () => {

    const navigate = useNavigate();

    const PRE_ANALYSIS_STATUS = new Map([
        ['APPROVE', 'pré-análise aprovada'],
        ['REJECTE', 'pré-análise reprovada'],
        ['MANUAL_APPROVE', 'pré-análise aprovada'],
        ['MANUAL_REJECT', 'pré-análise reprovada'],
        ['', 'Sem pré-análise']
      ]);

    const optionsStatus = [{
        value: 'approved',
        label: 'Aprovado'
    }, {
        value: 'not-approved',
        label: 'Reprovado'
    }, {
        value: 'pending',
        label: 'Pendente'
    }, {
        value: 'processing',
        label: 'Em Processamento'
    }, {
        value: 'error',
        label: 'Erro de Processamento'
    }]

    const { page, pageSize, field: fieldSorted,
        order: orderSorted, filter }: any = useParams();

    let [txtSocialId, setTxtSocialId] = useState('');
    let [txtRecomendation, setTxtRecomendation] = useState('');
    let [txtPartnerName, setTxtPartnerName] = useState('');

    let paramPartnerName = '';
    let paramRecomendation = '';
    let paramSocialId = '';

    if (filter)
        [paramPartnerName = '', paramRecomendation = '', paramSocialId = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

    const url = `${BASE_REFERRAL_URL}/loan-order-unlinked-list?socialId=${paramSocialId}`
        + `&page=${page}&pageSize=${pageSize}&recomendation=${paramRecomendation}&partner=${paramPartnerName}`;


    const navigateForm = (option: string, id: string) => {

        const mapper: Record<string, string> = {
            "btn-approve": "form-analysis-approve",
            "btn-reject": "form-analysis-reject",
            "btn-approve-reason": "form-analysis-approve-reason",
            "btn-reject-reason": "form-analysis-reject-reason"
        }

        const type = mapper[option];

        navigate(`/referrals/loan-order-analysis/${type}/${id}`)
    }

    const renderRows = (rows: Referral[]) => {
        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={9} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhum registro encontrado...
                        </div>
                    </td>
                </tr>
            );
        }
        return rows.map((row: Referral, index: number) => (
            <tr key={row.referralId}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-2">{row.referralId}</td>
                <td className="py-2 px-2">{row.referralDate}</td>
                <td className="py-2 px-2 whitespace-nowrap">{row.socialId}</td>
                <td className="py-2 px-2">
                    <NewTooltip content={row.name} alignment={'right'}>
                        <div className="truncate">{row.name}</div>
                    </NewTooltip>
                </td>
            

                <td className="py-2 px-2"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px'
                }}
                >
                    <NewTooltip content={row.guarantorName} alignment={'right'}>
                        <div >{row.guarantorName}</div>
                    </NewTooltip>
                </td>



                <td className="py-2 px-2">
                    <div className="flex  items-center justify-end text-nowrap">
                        <span className="mr-2">{row.desiredValue}</span>
                    </div>
                </td>
                <td className="py-2 px-2">
                    <div className="flex  items-center justify-end">
                        <span className="mr-2">{row.creditLimitAvailableFormated}</span>
                    </div>
                </td>
                <td className="py-2 px-2" style={{ width:"100px" }} align="left">
                    {
                        Number(row.amountPreAnalysis) > 0 &&  row.preAnalysisId==null ? (
                           
                                <Link to={`/referrals/linkwithreferral/${row.referralUUId}`} style={{ display: 'flex', alignItems: 'center' }}>
                                    <NewTooltip content="Vincular">
                                        <FaLink style={{ color:'orange' }}  /> <span style={{ marginLeft: '0.5rem' }}></span>
                                    </NewTooltip>
                                </Link>
                            
                        ):(
                            <NewTooltip content="Nenhuma pré-analise registrada" >
                                <div className="truncate">--</div>
                            </NewTooltip>
                          
                        )
                    }
                </td>
            </tr>
        ));
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNavigate(page, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    }

    const pageSorted = (field: keyof Referral | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    }

    const socialIdSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, search, txtRecomendation, txtPartnerName), { replace: true });
    }

    const partnerSearch = (partnerName: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, partnerName), { replace: true });
    }

    const filterRecommendation = (recomendation: string) => {
        setTxtRecomendation(recomendation);
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtSocialId, recomendation, txtPartnerName), { replace: true });
    }

    const onFilterClean = () => {
        setTxtPartnerName('');
        setTxtRecomendation('');
        setTxtSocialId('');
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', '', ''), { replace: true });
    }

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        socialId: string, recomendation: string, partner: string) => {
        return `/referrals/list-unlinked-order/${page}/${pageSize}/${fieldSorted}/${orderSorted}/p=${partner};r=${recomendation};s=${socialId};`
    }

    return (

        <PageList
            pageTitle='Lista de Pedidos Não Vinculados'
            url={url}
            breadcrumb={[{ label: 'Pedidos não vinculados', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel='pedidos'
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-3 gap-1">
                        <ASearch
                            id="src-partner-name"
                            value={txtPartnerName}
                            label="Procurar por origem..."
                            onSearch={partnerSearch}
                            onChange={setTxtPartnerName}
                        />

                        <SelectFilter
                            id="slc-status"
                            label="Recomendação..."
                            value={txtRecomendation}
                            onChange={filterRecommendation}
                            defaultVoidOption={true}
                            options={optionsStatus}
                        />

                        <PageSearch
                            value={txtSocialId}
                            label='Procurar por CPF...'
                            onSearch={socialIdSearch}
                            filterCleanVisible={true}
                            filterCleanEnabled={(txtPartnerName || txtRecomendation || txtSocialId) ? true : false}
                            configureVisible={false}
                            onFilterClean={onFilterClean}
                            onChange={setTxtSocialId}
                        />
                    </div>),

                controlSlot: (null)
            }}
        </PageList>
    );
};

export default FarmerOrderUnlinkedListPage;
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSignal } from "@preact/signals-react";
import PresentationModal from "../../organisms/PresentationModal";
import { removeErrorField } from "../../../utils/formErrorUtils";
import { ErrorField } from "../../../types/ErrorField";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';

import { FaArrowRight } from "react-icons/fa";
import NewTooltip from "../../atoms/NewTooltip";
import { toast } from "react-toastify";
import { useState } from "react";

type Form = {
    profiles: string[];
}

const PROFILES = [{
    value: 'RECIPIENT',
    label: 'Beneficiário',
    isFixed: false,
}, {
    value: 'ISSUER',
    label: 'Emitente',
    isFixed: false,
}, {
    value: 'GUARANTOR-RETAIL',
    label: 'Avalista varejo',
    isFixed: false,
}, {
    value: 'GUARANTOR-WHOLESALE',
    label: "Avalista atacado",
    isFixed: false,
}];

const styles: StylesConfig<{ value: string; label: string; isFixed?: boolean }, true> = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

const orderOptions = (values: readonly { value: string; label: string; isFixed?: boolean }[]) => {
    return values
        .filter((v) => v.isFixed)
        .concat(values.filter((v) => !v.isFixed));
};

const SelectProfilesModal: React.FC = () => {

    const navigate = useNavigate();
    const errorFields = useSignal<ErrorField[]>([]);

    const [params, setParams] = useSearchParams();
    const profiles = params.get('profiles');

    const initialProfiles = profiles ? profiles?.split(',') : [];

    const form = useSignal<Form>({
        profiles: initialProfiles,
    });

    const fixedProfiles = initialProfiles.map(profile => ({
        value: profile,
        label: PROFILES.find(p => p.value === profile)?.label || profile,
        isFixed: true,
    }));

    const [selectedOptions, setSelectedOptions] = useState<readonly { value: string; label: string; isFixed?: boolean }[]>(orderOptions(fixedProfiles));

    const changeForm = (field: string, value: any) => {
        form.value = { ...form.value, [field]: value };
        removeErrorField(errorFields.value, field);
    };

    const submitForm = async () => {
        if (form.value.profiles.length === 0) {
            toast.error("Selecione ao menos um perfil!");
            return;
        }
        if (form.value.profiles.length > 0 && form.value.profiles.length == profiles?.split(',').length) {
            toast.error("Selecione um perfil diferente do perfil atual!");
            return;
        }
        console.log(form.value);
        window.alert("Formulário enviado com sucesso!");
    };

    const onChange = (
        newValue: OnChangeValue<{ value: string; label: string; isFixed?: boolean }, true>,
        actionMeta: ActionMeta<{ value: string; label: string; isFixed?: boolean }>,
    ) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = fixedProfiles;
                break;
        }

        const orderedValues = orderOptions(newValue);
        setSelectedOptions(orderedValues);
        changeForm('profiles', orderedValues.map(v => v.value));
    };

    const disableForward = () => {
        return selectedOptions.length === 0 || selectedOptions.length === initialProfiles.length;
    };

    return (
        <PresentationModal
            id="select-profiles-modal"
            show={true}
            onClose={() => navigate(-1)}
            header="Novo cadastro"
        >
            <div className="p-4">
                <div className="mb-4 h-64">
                    <label htmlFor="profiles">Perfis:</label>
                    <Select
                        inputId="profiles"
                        name="profiles"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        isClearable={selectedOptions.some((v) => !v.isFixed)}
                        options={PROFILES}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isLoading={false}
                        onChange={onChange}
                        placeholder="Selecione o(s) perfil(is) desejado(s)"
                        value={selectedOptions}
                        styles={styles}
                    />
                </div>
                <div className="flex justify-end">
                    <NewTooltip content={<div className="w-48">Cancelar a edição e voltar para a tela anterior</div>}>
                        <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
                        >
                            Cancelar
                        </button>
                    </NewTooltip>

                    <NewTooltip content={<div className="w-24">Avançar para a próxima etapa</div>}>
                        <button
                            type="button"
                            onClick={submitForm}
                            disabled={disableForward()}
                            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                        >
                            Avançar
                            <FaArrowRight className="ml-2" />
                        </button>
                    </NewTooltip>
                </div>

            </div>
        </PresentationModal>
    );
};

export default SelectProfilesModal;
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import PageForm from "../../templates/PageForm";
import NewTooltip from "../../atoms/NewTooltip";
import SelectFilter from "../../organisms/SelectFilter";
import { DEFAULT_SCHEDULED_TYPES } from "./NotificationPageList";
import useFetch from "../../../hooks/useFetch";
import { Link, useNavigate, useParams } from "react-router-dom";

const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
const DEFAULT_SCHEDULED_TYPE = 'WPP_DUPLICATE_BILLING_DUE_D-30';

const URL = `${process.env.REACT_APP_COMMUNICATION_API_URL}/calendar`;
const API_KEY = process.env.REACT_APP_COMMUNICATION_API_KEY || '';

interface UrlNavigateParams {
    scheduledType: string;
    year: number;
    month: number;
}

const NotificationCalendarPage = () => {

    const navigate = useNavigate();
    const { scheduledType, year, month }: any = useParams();

    const currentDate = year == 0 ? new Date() : new Date(year, month, 0);
    const txtScheduledType = (scheduledType == 0 ? DEFAULT_SCHEDULED_TYPE : scheduledType);
    const currentMonth = (month == 0 ? currentDate.getMonth() + 1 : month);
    const currentYear = (year == 0 ? currentDate.getFullYear() : year);

    const { isLoading, data, error, isTokenError } = useFetch(`${URL}/${txtScheduledType}/${currentYear}/${currentMonth}`, [
        { key: 'x-api-key', value: API_KEY },
    ]);

    const loadData = (!data) ? { total: 0, resume: [{ day: [] }] } : data;

    if (isTokenError) {
        navigate("/denied");
    }

    const filterScheduledTypeChange = (value: string) => {
        navigate(urlNavigate({ scheduledType: value, year: currentYear, month: currentMonth }), { replace: true });
    };

    const urlNavigate = ({ scheduledType, year, month }: UrlNavigateParams): string => {
        return `/communication/calendar/${scheduledType}/${year}/${month}`;
    };

    const getDayTotal = (dayNumber: number) => {
        const total = loadData?.resume.find((item: { day: number; total: number }) => item.day == dayNumber)?.total;
        if (total) return `Agendamentos: ${total}`;
        return "";
    }

    const handleMonthChange = (direction: "prev" | "next") => {

        if (direction === "prev") {
            if (currentMonth === 1) {
                navigate(urlNavigate({ scheduledType: txtScheduledType, year: parseInt(currentYear) - 1, month: 12 }), { replace: true });
            } else {
                navigate(urlNavigate({ scheduledType: txtScheduledType, year: currentYear, month: parseInt(currentMonth) - 1 }), { replace: true });
            }
        } else {
            if (currentMonth === 12) {
                navigate(urlNavigate({ scheduledType: txtScheduledType, year: parseInt(currentYear) + 1, month: 1 }), { replace: true });
            } else {
                navigate(urlNavigate({ scheduledType: txtScheduledType, year: currentYear, month: parseInt(currentMonth) + 1 }), { replace: true });
            }
        }

    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };

    const generateCalendar = () => {
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
        const daysInMonth = getDaysInMonth(currentYear, currentMonth);
        const weeks: number[][] = [];
        let week: number[] = Array(firstDayOfMonth).fill(0);

        for (let day = 1; day <= daysInMonth; day += 1) {
            week.push(day);
            if (week.length === 7) {
                weeks.push(week);
                week = [];
            }
        }

        if (week.length > 0) {
            while (week.length < 7) {
                week.push(0);
            }
            weeks.push(week);
        }

        return weeks;
    };

    return (
        <PageForm
            pageTitle={`Agendamentos: ${loadData?.total}`}
            isLoading={isLoading}
            breadcrumb={[{ "label": "Agendamentos", "url": "#" }]}
            apiVersion={`${loadData?.apiName} - ${loadData?.apiVersion}`}
            controlSlot={(
                <div className="flex gap-4 m-4">
                    <SelectFilter
                        id="slc-scheduled-type"
                        label="Tipo de notificação ..."
                        value={txtScheduledType}
                        onChange={filterScheduledTypeChange}
                        defaultVoidOption={true}
                        options={DEFAULT_SCHEDULED_TYPES}
                    />

                    <NewTooltip content={`Mês anterior ${currentMonth}`} alignment="bottom">
                        <button
                            onClick={() => handleMonthChange("prev")}
                            className="p-3 bg-gray-200 rounded hover:bg-gray-300"
                        >
                            <IoIosArrowBack />
                        </button>
                    </NewTooltip>

                    <h2 className="text-lg font-semibold ">
                        {currentDate.toLocaleString("pt-BR", { month: "long", year: "numeric" })}
                    </h2>

                    <NewTooltip content="Próximo mês" alignment="bottom">
                        <button
                            onClick={() => handleMonthChange("next")}
                            className="p-3 bg-gray-200 rounded hover:bg-gray-300"
                        >
                            <IoIosArrowForward />
                        </button>
                    </NewTooltip>
                </div>
            )}
        >
            <div className="flex flex-col h-full">
                <div className="grid grid-cols-7 text-center font-semibold border-b pb-2">
                    {daysOfWeek.map((day) => (
                        <div key={day} className="p-2">{day}</div>
                    ))}
                </div>
                <div className="grid grid-cols-7 gap-1 flex-1">
                    {generateCalendar().flat().map((day, index) => (
                        <Link key={`link-${index}`} to={`/communication/notification/0/100/id/ASC/f=;s=;d=${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')};`}
                            className={`group p-4 border rounded text-center ${day === 0 ? "text-gray-300" : "hover:bg-blue-200 cursor-pointer"
                                }`}>
                            {day !== 0 && <span className="rounded-full p-2 group-hover:bg-blue-400 group-hover:text-white ">{day}</span>}

                            <div className="text-sm pt-2 content-end">
                                {getDayTotal(day)}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </PageForm>
    );
}

export default NotificationCalendarPage;
import { FC, useState } from "react";
import DateInput from "../../molecules/DateInput";
import ActionButton from "../../molecules/ActionButton";
import { useNavigate } from "react-router-dom";
import DisbursementDataTable from "./DisbursementDataTable";
import { ATableHeader } from "../../organisms/ATable";
import { toast } from "react-toastify";

type FormProps = {
    token: string;
};

const BASE_URL = process.env.REACT_APP_CONTRACT_REGISTER_API_URL;


const headers = [
    { id: 2, title: "CPRF", field: "cprf" },
    { id: 3, title: "STATUS", field: "status" },
    { id: 4, title: "Nome do Produtor", field: "farmerMame" },
    { id: 5, title: "Valor", field: "value" }
];

const ReprocessForm: FC<FormProps> = ({ token }) => {
    const [buttonEnable, setButtonEnabled] = useState(false);
    const [dataForm, setDataForm] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: '',
        visible: false
    });

    const navigate = useNavigate();

    const handleChange = (date: string) => {
        setSelectedDate(date);
    };

    const handleDataLoaded = (hasPending: boolean, formDate: string) => {
        setButtonEnabled(hasPending);
        setDataForm(formDate);
    };

    const reprocessDisbursement = async (date: string) => {
        try {
            const response = await fetch(`${BASE_URL}/maintenance/disbursement/reprocess/${date}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                mode: 'cors'
            });

            if (!response.ok) throw new Error('Network response was not ok');
            toast.info("Ação realizada com sucesso!");
        } catch (error) {
            toast.error('Erro ao realizar ação');
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <div className="grid grid-cols-2 gap-2 p-2">
                <div className="col-4">
                    <DateInput label="Data dos desembolsos: " onChange={handleChange} />
                </div>
            </div>
            {selectedDate && (
                <>
                    {buttonEnable && (
                        <div className="col-4 flex justify-end">
                            <ActionButton label="Enviar Desembolsos" onClick={() => reprocessDisbursement(dataForm)} />
                        </div>
                    )}
                    <DisbursementDataTable
                        token={token}
                        headers={headers as unknown as ATableHeader[]}
                        selectedDate={selectedDate}
                        onDataLoaded={handleDataLoaded}
                    />
                </>
            )}
        </div>
    );
};

export default ReprocessForm;

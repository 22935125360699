import React, { useEffect } from "react";
import PageForm from "../../templates/PageForm";
import { useNavigate, useParams } from "react-router-dom";
import TextEdit from "../../molecules/TextEdit";
import { FaWhatsapp, FaWpforms } from "react-icons/fa6";
import { useSignal } from "@preact/signals-react";
import { FaMailBulk } from "react-icons/fa";
import CopyPasteTable from "../../organisms/CopyPasteTable";
import SelectFilter from "../../organisms/SelectFilter";

import { toast } from "react-toastify";
import { ErrorField } from '../../../types/ErrorField';
import { getErrorMessage, hasErrorMessage, removeErrorField } from '../../../utils/formErrorUtils';
import useFetch from "../../../hooks/useFetch";
import { NotifyMailTemplate, NotifyTemplate } from "../../../types/NotificationTemplate";

const NOTIFICATION_URL = `${process.env.REACT_APP_COMMUNICATION_API_URL}/notifications`;
const API_KEY = `${process.env.REACT_APP_COMMUNICATION_API_KEY}`;

enum MediaType {
    WPP = "WPP",
    MAIL = "MAIL",
}

type CreationType = {
    type: "batch" | "single";
    pageTitle: string;
}

type SendOption = "immediate" | "later";
type Variable = {
    field: string;
    value: string;
}

type Form = {
    sendOption: SendOption;
    operation: string;
    contactName: string;
    phoneNumber: string;
    email: string;
    cc: string;
    bcc: string;
    variables: Variable[];
    scheduledDateType: string;

    template: NotifyTemplate;

    notifications?: [];
}

const BatchNotificationForm: React.FC = () => {
    const navigate = useNavigate();
    const { scheduledType, targetDate } = useParams();

    const creationtype = useSignal<CreationType>({
        type: "single",
        pageTitle: "Criar notificação",
    });

    const mediaType = scheduledType?.split("_")[0];

    const isLoadingForm = useSignal<boolean>(false);
    const errorFields = useSignal<ErrorField[]>([]);
    const apiVersion = useSignal('');

    const form = useSignal<Form>({
        sendOption: "later",
        operation: "",
        contactName: "",
        phoneNumber: "",
        email: "",
        cc: "",
        bcc: "",
        variables: [],
        scheduledDateType: "fixed",
        template: {
            description: "",
            variables: [],
            batchHeaders: [],
            sendOption: ["immediate", "later"],
        } as NotifyTemplate,
    });

    const { isLoading, data, error, isTokenError } = useFetch(`${NOTIFICATION_URL}/page-form/${scheduledType}`, [{ key: 'x-api-key', value: API_KEY }]);

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    useEffect(() => {
        if (data) {
            apiVersion.value = `${data.apiName} - ${data.apiVersion}`;

            form.value = {
                ...form.value,
                template: {
                    ...form.value.template,
                    description: data.description,
                    sendOption: data.sendOption,
                    batchHeaders: data.batchHeaders,
                    variables: data.variables,

                    ...(mediaType === MediaType.MAIL && {
                        subject: data.subject,
                        from: data.from,
                        replyTo: data.replyTo,
                        showCcField: data.showCcField,
                        showBccField: data.showBccField,
                    }),
                },
                variables: data.variables.map((variable: Variable) => ({
                    field: variable.field,
                    value: "",
                })),
            };
        }
    }, [data]);

    useEffect(() => {
        const handleEvent = (event: CustomEvent) => {
            form.value = {
                ...form.value,
                notifications: event.detail,
            };
        };

        window.addEventListener("CopyPasteTable:jsonGenerated", handleEvent as EventListener);
        return () => window.removeEventListener("CopyPasteTable:jsonGenerated", handleEvent as EventListener);
    }, []);

    const clearData = () => {
        window.dispatchEvent(new Event("CopyPasteTable:clearData"));
    };

    const changeCreationType = (type: "batch" | "single") => {
        creationtype.value = {
            type,
            pageTitle: type === "batch" ? "Criar notificação em lote" : "Criar notificação",
        };
    };

    const submitForm = async () => {
        isLoadingForm.value = true;

        try {
            let url = NOTIFICATION_URL;
            let body = {};
            let successMessage = "Notificação criada com sucesso!";
            if (creationtype.value.type === "batch") {
                url = `${url}/batch`;
                body = {
                    scheduledType: scheduledType,
                    targetDate: targetDate,
                    sendOption: form.value.sendOption,
                    mediaType,
                    notifications: form.value.notifications,
                };
                successMessage = "Notificações criadas com sucesso!";
            }
            if (creationtype.value.type === "single") {
                body = {
                    scheduledType: scheduledType,
                    targetDate: targetDate,
                    sendOption: form.value.sendOption,
                    mediaType,
                    operation: form.value.operation,
                    contactName: form.value.contactName,
                    phone: form.value.phoneNumber,
                    email: form.value.email,
                    cc: form.value.cc,
                    variables: form.value.variables.reduce((acc, variable) => {
                        acc[variable.field] = variable.value;
                        return acc;
                    }, {} as Record<string, string>),
                };
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY,
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                toast.info(successMessage);
                navigate(-1);
            } else {
                if (creationtype.value.type === "batch") {
                    const { result } = await response.json();
                    toast.error("Algumas notificações não foram criadas. Verifique os erros na lista!");
                    window.dispatchEvent(new CustomEvent("CopyPasteTable:setErrors", { detail: result }));
                } else {
                    const { errors, message } = await response.json();
                    toast.error(message);
                    errorFields.value = errors ? errors.map((error: any) => ({ fieldName: error.field, message: error.message })) : [];
                }
            }

        } catch (error) {
            toast.error("Ocorreu um erro com a rede. Tente novamente mais tarde.");
        }
        isLoadingForm.value = false;
    };

    const changeForm = (field: string, value: string) => {
        form.value = {
            ...form.value,
            [field]: value,
        };
        removeErrorField(errorFields.value, field);
    };

    const cancelForm = () => {
        navigate(-1);
    };

    const changeFormVariables = (field: string, value: string) => {
        const newVariables = form.value.variables.map(variable => {
            if (variable.field === field) {
                return { ...variable, value };
            }
            return variable;
        });
        form.value = { ...form.value, variables: newVariables };
    };

    return (
        <PageForm
            pageTitle={creationtype.value.pageTitle}
            apiVersion={apiVersion.value}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={[
                { label: "Notificações do dia", url: "/communication/notification/0/100/contactFullName/ASC/" },
                { label: creationtype.value.pageTitle, url: "#" },
            ]}
            controlSlot={(
                <div className="flex space-x-4 justify-end m-2">
                    <button
                        className="bg-zinc-600 text-white px-4 py-2 rounded inline-flex items-center"
                        onClick={() => changeCreationType(creationtype.value.type === "batch" ? "single" : "batch")}
                        disabled={isLoading || isLoadingForm.value}
                    >
                        <FaWpforms className="mr-2" />
                        {creationtype.value.type === "batch" ? "Simples" : "Em lote"}
                    </button>
                    {creationtype.value.type === "batch" && (
                        <button
                            className="bg-red-500 text-white px-4 py-2 rounded"
                            onClick={clearData}
                            disabled={isLoading || isLoadingForm.value}
                        >
                            Limpar Dados
                        </button>
                    )}
                    <button
                        className='text-black bg-slate-200 px-4 py-2 hover:bg-slate-400 rounded'
                        onClick={cancelForm}
                        title="Cancelar registro"
                        disabled={isLoading || isLoadingForm.value}
                    >
                        Cancelar
                    </button>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-800"
                        onClick={submitForm}
                        title="Salvar registro"
                        disabled={isLoading || isLoadingForm.value}
                    >
                        Salvar
                    </button>
                </div>
            )}
        >

            <div className="container mx-auto p-4">
                <div className="grid grid-cols-12 gap-4 mb-4">
                    <div className={`${creationtype.value.type === "batch" ? "col-span-5" : "col-span-6"}`}>
                        <TextEdit label="Tipo de notificação:" value={form.value.template.description} disabled />
                    </div>
                    {creationtype.value.type === "batch" && (
                        <div className="col-span-2">
                            <SelectFilter
                                id="scheduledDateType"
                                label="Opção de agendamento:"
                                value={form.value.scheduledDateType}
                                options={[
                                    { value: "fixed", label: "Data fixa" },
                                    { value: "fromFile", label: "Data personalizada", disabled: true },
                                ]}
                                onChange={(value) => changeForm("scheduledDateType", value)}
                            />
                        </div>
                    )}

                    <div className={`${creationtype.value.type === "batch" ? "col-span-2" : "col-span-3"}`}>
                        <TextEdit label="Data do agendamento:"
                            className={`${form.value.scheduledDateType === "fromFile" && creationtype.value.type === "batch" ? "line-through text-gray-200" : ""}`}
                            value={targetDate?.split("-").reverse().join("/")} disabled />
                    </div>

                    <div className="row-span-2 col-span-3 p-4 bg-white rounded-lg shadow-md border border-gray-200">
                        {mediaType === MediaType.WPP && (
                            <FaWhatsapp className="col-auto text-3xl text-green-700 float-right mt-2" />
                        )}
                        {mediaType === MediaType.MAIL && (
                            <FaMailBulk className="col-auto text-3xl text-blue-700 float-right mt-2" />
                        )}

                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                                type="radio"
                                name="sendOption"
                                value="later"
                                checked={form.value.sendOption === "later"}
                                onChange={() => changeForm("sendOption", "later")}
                                className="hidden"
                            />
                            <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
                                ${form.value.sendOption === "later" ? "border-blue-500" : "border-gray-400"}`}>
                                {form.value.sendOption === "later" && <div className="w-2.5 h-2.5 bg-blue-500 rounded-full"></div>}
                            </div>
                            <span className="text-gray-900 text-sm font-medium">Gravar para enviar depois</span>
                        </label>

                        <label className="flex items-center space-x-2 cursor-pointer mt-1">
                            <input
                                type="radio"
                                name="sendOption"
                                value="immediate"
                                checked={form.value.sendOption === "immediate"}
                                onChange={() => changeForm("sendOption", "immediate")}
                                className="hidden"
                                disabled={true}
                            />
                            <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
                                ${form.value.sendOption === "immediate" ? "border-blue-500" : "border-gray-400"}`}>
                                {form.value.sendOption === "immediate" && <div className="w-2.5 h-2.5 bg-blue-500 rounded-full"></div>}
                            </div>
                            <span className="text-gray-900 text-sm font-medium">Enviar imediatamente</span>
                        </label>
                    </div>

                    {mediaType === MediaType.MAIL && (
                        <>
                            <div className="col-span-4">
                                <TextEdit label="Titulo da mensagem:" value={(form.value.template as NotifyMailTemplate).subject} disabled />
                            </div>
                            <div className="col-span-5 grid grid-cols-2 gap-4">
                                <div className="col-span-1">
                                    <TextEdit label="De:" value={(form.value.template as NotifyMailTemplate).from} disabled />
                                </div>
                                <div className="col-span-1">
                                    <TextEdit label="Responder para:" value={(form.value.template as NotifyMailTemplate).replyTo} disabled />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {creationtype.value.type === "batch" ? (
                    <div className="grid grid-cols-1">
                        <CopyPasteTable fixedHeaders={form.value.template.batchHeaders} />
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-12 gap-4 mt-4 pt-4">
                            <div className="col-span-2">
                                <TextEdit label="Número da operação:" value={form.value.operation}
                                    placeholder=""
                                    onChange={(e) => changeForm("operation", e.target.value)}
                                    errorMessage={getErrorMessage(errorFields.value, "operation")}
                                    maxLength={25} />
                            </div>
                            <div className="col-span-7">
                                <TextEdit label="Nome do contato:" value={form.value.contactName}
                                    placeholder=""
                                    onChange={(e) => changeForm("contactName", e.target.value)}
                                    errorMessage={getErrorMessage(errorFields.value, "contact_name")}
                                    maxLength={250} />
                            </div>
                            {mediaType === MediaType.WPP && (
                                <div className="col-span-3">
                                    <TextEdit label="Telefone:" value={form.value.phoneNumber}
                                        placeholder=" "
                                        onChange={(e) => changeForm("phoneNumber", e.target.value)}
                                        errorMessage={getErrorMessage(errorFields.value, "phone")}
                                        maxLength={15}
                                        prefix="+55"
                                        labelClassName="ml-8"
                                    />
                                </div>
                            )}
                            {mediaType === MediaType.MAIL && (
                                <>
                                    <div className="col-start-1 col-span-4">
                                        <TextEdit label="Email:" value={form.value.email}
                                            placeholder=""
                                            onChange={(e) => changeForm("email", e.target.value)}
                                            errorMessage={getErrorMessage(errorFields.value, "email")}
                                            maxLength={200} />
                                    </div>
                                    {(form.value.template as NotifyMailTemplate).showCcField && (
                                        <div className="col-span-4">
                                            <TextEdit label="Cc:" value={form.value.cc}
                                                placeholder=""
                                                onChange={(e) => changeForm("cc", e.target.value)}
                                                errorMessage={getErrorMessage(errorFields.value, "cc")}
                                                maxLength={200}
                                            />
                                        </div>
                                    )}
                                    {(form.value.template as NotifyMailTemplate).showBccField && (
                                        <div className="col-span-4">
                                            <TextEdit label="Bcc:" value={form.value.bcc}
                                                placeholder=""
                                                onChange={(e) => changeForm("bcc", e.target.value)}
                                                errorMessage={getErrorMessage(errorFields.value, "bcc")}
                                                maxLength={200}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="grid grid-cols-12 gap-4 mt-4">
                            {form.value.template.variables.map((variable, index) => (
                                <div key={index} className="col-start-1 col-span-5">
                                    <TextEdit
                                        label={`${variable.label}:`}
                                        value={form.value.variables.find(v => v.field === variable.field)?.value || ""}
                                        onChange={(e) => changeFormVariables(variable.field, e.target.value)}
                                        errorMessage={hasErrorMessage(errorFields.value, variable.field) ? getErrorMessage(errorFields.value, variable.field) : ""}
                                        placeholder=""
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

        </PageForm>
    );
};

export default BatchNotificationForm;
import { useState, KeyboardEvent, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";

type ASearchProps = {
	id: string,
	value: string;
	disabled?: boolean;
	label?: string;
	onSearch?: (query: string) => void;
	onChange?: (value: string) => void;
	className?: string;
}

const ASearch: React.FC<ASearchProps> = ({ id, value, disabled, label = "Procurar por...",
	onSearch = () => { }, onChange, className }) => {
	const [searchQuery, setSearchQuery] = useState(value);

	useEffect(() => {
		setSearchQuery(value);
	}, [value]);


	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setSearchQuery(newValue);
		// Chamar onChange para atualizar o valor no componente pai
		if (onChange) onChange(newValue);
	  };
	

	const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearch(searchQuery);
		}		
	};

	return (
		<div className={`relative ${className}`}>
			<input
				id={id}
				type="text"
				className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-white border border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-600 peer mb-1 appearance-none"
				placeholder=" "
				value={searchQuery}
				onChange={handleInputChange}
				onKeyUp={handleKeyUp}
			/>

			<label htmlFor={id}
				className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
				{label}
			</label>

			<div className="absolute inset-y-0 right-3 flex items-center">
				<AiOutlineSearch className="text-gray-500 h-6 w-6" />
			</div>
		</div>
	);
}

export default ASearch;
import { SetStateAction, useEffect, useRef, useState } from 'react';
import PageForm from '../../templates/PageForm';
import { useNavigate, useParams } from 'react-router-dom';
import Tooltip from '../../atoms/Tooltip';
import TextEdit from "../../molecules/TextEdit";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ATable from "../../organisms/ATable";
import { useSignal } from '@preact/signals-react';
import { ErrorField } from '../../../types/ErrorField';
import ConfirmationModal from '../../templates/ConfirmationModal';




type Analysis = {
    referralId: string;
    guarantorName: string;
    referralUUId: string;
    partnerOriginName:string;
    analysedAt: string;
    socialId: string;
    desiredValue: string;
    approvedValue: string;
    recommendation: string;
    analysesType: string;
}
type FrontPageData = {
    socialId: string;
    farmerName: string;
    guarantorName:string;
    desiredValue: string;
    loanOrderUUID: string;
    listUnlinked: Analysis[];
}



const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/loan-order-analysis`;


const LinkUpReferralWithLoanOrderFormPage = () => {

    const navigate = useNavigate();
    const token = useRef('');
    const [selectedId, setSelectedId] = useState("");
    const [apiVersion, setApiVersion] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [frontPageData,setFrontPageData] = useState({
        socialId: '',
        farmerName: '',
        guarantorName:'',
        desiredValue: '',
        loanOrderUUID: '',
        listUnlinked: []
    })


    const columns = [{
        id: "column1",
        title: "#",
        class: 'text-center',
        field: 'id',
        canSort: false
    }, 
    {
        id: "column2",
        title: 'Origem',
        width: '30%',
        field: "name",
        canSort: false
    },{
        id: "column2",
        title: 'Avalista',
        width: '30%',
        field: "name",
        canSort: false
    }, {
        id: "column3",
        title: 'Data',
        canSort: false,
        headerTooltipDescription: 'Data da análise',
    }, {
        id: "column4",
        title: 'Valor aprovado',
        field: "approvedValue",
        headerTooltipDescription: 'Valor aprovado na pré-análise',
        canSort: false
    }, {
        id: "column5",
        title: 'Recomendação',
        field: "recommendation",
        canSort: false
    },{
        id: "column6",
        title: ' ',
        field: "actions",
        canSort: false
    }];

    const handleRadioChange = (event:any) => {
        setSelectedId(event.target.value);
    };

    const handleSubmit = () => {
        submitForm();
    };

    const getTranslatedRecommendation = (recommendation: string) => {
        switch (recommendation.toUpperCase()) {
            case "APPROVED":
                return "Aprovado";
            case "NOT_APPROVED":
                return "Reprovado";
            case "PENDING":
                return "Pendente";
            default:
                return "";
        }
    }


    const {loanOrderUUID}: any = useParams();
    const renderRows = (rows: Analysis[]) => {
        return rows.map((row: Analysis, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                <td className="py-2 px-2 border-b border-gray-300">{row.referralId}</td>
                <td className="py-2 px-2 border-b border-gray-300"
                    style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px'}}
                >
                    <Tooltip content={row.partnerOriginName}  alignment={'right'}>
                        <div className="truncate">{row.partnerOriginName}</div>
                    </Tooltip>
                </td>

                <td className="py-2 px-2 border-b border-gray-300"
                     style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px'}}
                >
                    <Tooltip content={row.guarantorName} alignment={'right'}>
                        <div className="truncate">{row.guarantorName}</div>
                    </Tooltip>
                </td>
                <td className="py-2 px-2 border-b border-gray-300">
                        {row.analysedAt}
                </td>
                <td className="py-2 px-2 border-b border-gray-300">{row.approvedValue}</td>
                <td className="py-2 px-2 border-b border-gray-300">{getTranslatedRecommendation(row.recommendation)}</td>
                <td className="py-2 px-2 border-b border-gray-300">
                    <input
                        type="radio"
                        name="selectedAnalysis" // Mesmo name para permitir apenas uma seleção
                        value={row.referralUUId}
                        onChange={handleRadioChange}
                        // Se os ids são números, pode converter: selectedId === row.referralId
                       checked={selectedId === row.referralUUId}
                />
                </td>
            </tr>
        ));
    };
    
    useEffect(() => {
        Auth.currentSession()
            .then((user) => user.getIdToken().getJwtToken())
            .then((tkn) => {
                token.current = tkn;
                return tkn;
            })
            .then((token) => fetchData(token))
            .catch((error) => {
                navigate("/denied");
                console.error('Error during authentication:', error);
            });
    }, []);

    const fetchData = (token: string) => {
        fetch(`${BASE_REFERRAL_URL}/unlinked/${loanOrderUUID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors'
        })
            .then(response => response.json())
            .then(data => {
                setFrontPageData(data);
                setApiVersion(`${data.apiName} - ${data.apiVersion}`);
            isLoadingForm.value = false;
             
          
            }).catch(() => navigate('/network-error'))
    }



    const isLoadingForm = useSignal(true);
    const errorFields = useSignal<ErrorField[]>([]);

    const url = BASE_REFERRAL_URL + "/batch-analysis-form";
   
    const cancelForm=()=>{  
        navigate(-1);
    }

    const showConfirmation=()=>{  
        if(selectedId === ""){
            toast.error("Selecione uma análise para vincular");
            return;
        }
        setIsConfirmationOpen(true);
    }

    const closeConfirmation=()=>{  
        setIsConfirmationOpen(false);
    }

    const submitForm = async () => {
        
        const formData = new FormData();
        formData.append('loanOrderUUID', frontPageData.loanOrderUUID);
        formData.append('referralUUID', selectedId);
        const token = await Auth.currentSession()
            .then((user) => user.getIdToken()
                .getJwtToken());

        const response = await fetch(BASE_REFERRAL_URL + "/to-link", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                loanOrderUUID:frontPageData.loanOrderUUID,
                referralUUID:selectedId
            })
        });

        if (response.ok) {
            toast.info("Gravando vinculação...");
            navigate(-1);
        } else {
            const { message, errors, title } = await response.json();
            toast.error(message);
        }
        isLoadingForm.value = false;
        closeConfirmation();
    }

    return (

        <PageForm
            pageTitle='Vincular pedido com pré-analise'
            apiVersion={apiVersion}
            isLoading={isLoadingForm.value}
            breadcrumb={[{ label: 'Pedido produtor', url: "---" }, { label: 'Vincular pedido com pré-analise', url: '#' }]}
            controlSlot={(
                    <div className='pt-4 text-right' >
                        <button type='button'
                            className='text-black bg-gradient-to-r from-slate-200 via-slate-300 to-slate-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                            onClick={cancelForm}
                            disabled={isLoadingForm.value}
                        >
                            <div className="flex items-center">
                                Cancelar
                            </div>
                        </button>

                        <button type='button'
                            disabled={selectedId === ""}
                            className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                            onClick={showConfirmation}>
                            <div className="flex items-center">
                                Vincular
                            </div>
                        </button>

                        <ConfirmationModal
                            isOpen={isConfirmationOpen}
                            onCancel={closeConfirmation}
                            onConfirm={handleSubmit}
                            title="Confirmar vinculação"
                            id={selectedId}
                            text="Deseja realmente vincular o pedido com a pré-análise?"
                        />
                    </div>
            )}
        >

                    <div className="container  px-4 py-8">
                        <div className="grid grid-cols-2 gap-1">
                        <div className="mb-4">
                            <TextEdit
                                    label="Produtor:"
                                    value={frontPageData.farmerName}
                                    disabled={true}
                            />
                        </div>



                        <div className="mb-4">
                            <TextEdit
                                    label="CPF:"
                                    value={frontPageData.socialId}
                                    disabled={true}
                            />
                        </div>
                        </div>
                        
                        <div className="grid grid-cols-2 gap-1">
                        <div className="mb-4">
                            <TextEdit
                                    label="Avalista:"
                                    value={frontPageData.guarantorName}
                                    disabled={true}
                            />
                        </div>

                        <div className="mb-4">
                             <TextEdit
                                    label="Valor solicitado:"
                                    value={frontPageData.desiredValue}
                                    disabled={true}
                            />
                        </div>
                        </div>
                        <div className="mt-10">
                            <ATable 
                                headers={columns}>
                                {renderRows(frontPageData.listUnlinked)}
                            </ATable>
                        </div>
                    </div>

        </PageForm>
    );

}

export default LinkUpReferralWithLoanOrderFormPage;

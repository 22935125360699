import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageForm from "../../templates/PageForm";
import TextEdit from "../../molecules/TextEdit";
import DayPicker from "../../molecules/DayPicker";
import CurrencyInput from "../../molecules/CurrencyInput";


const NotificationFormPage = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        media: "WHATSAPP",
        messageTemplate: "",
        operationNumber: "",
        contactName: "",
        email: "",
        phone: "",
        dueDate: "",
        titleNumber: "",
        amount: 0,
    });

    const handleChange = (field: string, value: string | number) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = () => {
        console.log("Updating Data:", formData);
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleRemove = () => {
        console.log("Removing Record");
    };

    return (
        <PageForm
            pageTitle="Alterar Notificação"
            apiVersion="v1.0.0"
            isLoading={false}
            breadcrumb={[{ label: 'Home', url: '/' }, { label: 'Alterar Notificação"', url: '#' }]}
            controlSlot={(
                <div className="pt-4 flex justify-end space-x-2">
                    <button
                        type="button"
                        className="text-black bg-gray-300 hover:bg-gray-400 px-5 py-2.5 rounded"
                        onClick={handleCancel}
                    >
                        Cancelar
                    </button>

                    <button
                        type="button"
                        className="text-white bg-blue-500 hover:bg-blue-600 px-5 py-2.5 rounded"
                        onClick={handleSubmit}
                    >
                        Atualizar
                    </button>
                </div>
            )}
        >
            <div className="container mx-auto px-4 py-8 grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Mídia</label>
                    <select
                        className="block w-full mt-1 p-2 border rounded"
                        value={formData.media}
                        onChange={(e) => handleChange("media", e.target.value)}
                    >
                        <option value="WHATSAPP">WhatsApp</option>
                        <option value="EMAIL">Email</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Modelo da Mensagem</label>
                    <select
                        className="block w-full mt-1 p-2 border rounded"
                        value={formData.messageTemplate}
                        onChange={(e) => handleChange("messageTemplate", e.target.value)}
                    >
                        <option value="">Selecione</option>
                        <option value="EMAIL_30_DAYS">Cobrança Email - 30 dias</option>
                        <option value="WHATSAPP_30_DAYS">Cobrança WhatsApp - 30 dias</option>
                    </select>
                </div>

                <TextEdit label="Número da Operação" value={formData.operationNumber} onChange={(e) => handleChange("operationNumber", e.target.value)} />
                <TextEdit label="Nome do Contato" value={formData.contactName} onChange={(e) => handleChange("contactName", e.target.value)} />

                {formData.media === "EMAIL" && (
                    <TextEdit label="Email" value={formData.email} onChange={(e) => handleChange("email", e.target.value)} type="email" />
                )}

                {formData.media === "WHATSAPP" && (
                    <TextEdit label="Telefone" value={formData.phone} onChange={(e) => handleChange("phone", e.target.value)} placeholder="(99) 99999-9999" />
                )}

                <DayPicker label="Vencimento" value={formData.dueDate} onChange={(value) => handleChange("dueDate", value)} />
                <TextEdit label="Número do Título" value={formData.titleNumber} onChange={(e) => handleChange("titleNumber", e.target.value)} />
                <CurrencyInput label="Valor" value={formData.amount} onChange={(value) => handleChange("amount", value)} />
            </div>
        </PageForm>
    );
};

export default NotificationFormPage;

import React, { useState, useRef, useEffect } from 'react';

export type MenuOption = {
  key: string,
  label?: string;
  backgroundColor?: string;
  type: 'item' | 'separator';
  visible?: boolean | undefined;
};

type MenuButtonProps = {
  options: MenuOption[];
  onSelect: (option: string) => void;
};

const MenuButton: React.FC<MenuButtonProps> = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAbove, setShowAbove] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuContentRef = useRef<HTMLDivElement>(null);
  
  const handleToggle = () => {
    // Calcular a posição antes de abrir o menu
    if (!isOpen) {
      setTimeout(() => calculatePosition(), 0);
    }
    setIsOpen(!isOpen);
  };

  options = options.map(option => ({ ...option, visible: option.visible ?? true }));

  const handleOptionClick = (event: React.MouseEvent, option: string) => {
    event.stopPropagation();
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const calculatePosition = () => {
    if (menuRef.current && menuContentRef.current) {
      const buttonRect = menuRef.current.getBoundingClientRect();
      const menuHeight = menuContentRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      
      // Verifica se há espaço suficiente abaixo do botão
      const spaceBelow = windowHeight - buttonRect.bottom;
      setShowAbove(spaceBelow < menuHeight && buttonRect.top > menuHeight);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', calculatePosition);
    window.addEventListener('scroll', calculatePosition);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', calculatePosition);
      window.removeEventListener('scroll', calculatePosition);
    };
  }, []);
  
  useEffect(() => {
    if (isOpen) {
      calculatePosition();
    }
  }, [isOpen]);

  return (
    <div className="relative inline-block" ref={menuRef}>
      <button
        onClick={handleToggle}
        className="p-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        <span className="sr-only">Open menu</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 6v.01M12 12v.01M12 18v.01"
          />
        </svg>
      </button>

      {isOpen && (
        <div 
          ref={menuContentRef}
          className={`absolute right-0 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10 ${
            showAbove ? 'bottom-full mb-2' : 'top-full mt-2'
          }`}
        >
          <ul className='p-2 bg-slate-100 drop-shadow-md'>
            {
              options
                .filter(option => option.visible === true)
                .map(({ key, label, backgroundColor, type }, index) => (
                  type === 'separator' ? (
                    <hr key={`separator-${index}`} className="border-gray-300 my-2" />
                  ) : (
                    <li
                      key={key}
                      className={`block px-4 py-2 cursor-pointer text-white mb-1 border hover:bg-slate-600 rounded-md bg-[${backgroundColor}]`}
                      onClick={(event) => handleOptionClick(event, key)}
                      style={{ backgroundColor: backgroundColor }}
                    >
                      {label}
                    </li>
                  )
                ))
            }
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuButton;
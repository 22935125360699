import { Fragment } from "react";
import PresentationModal from "../../organisms/PresentationModal";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../ions/Loading";
import ATable from "../../organisms/ATable";
import useFetch from "../../../hooks/useFetch";
import Tooltip from "../../atoms/Tooltip";

interface CalculationFactor {
	factorName: string,
	description: string,
	value: string
}

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/referrals`;

const ReferralDetailModal = () => {

	const navigate = useNavigate();
	const { referralId } = useParams();

	const url = BASE_REFERRAL_URL + `/detail-modal/${referralId}`;

	const { isLoading, data, error, isTokenError } = useFetch(url);

	if (isTokenError) {
		navigate("/denied");
	}

	if (error) {
		navigate("/network-error");
	}

	const modalProviderRows4 = () => {
		if (data == null || data.providers == null)
			return [];
		let nRow = 0;
		let table = [];
		for (let i = 0; i < data.providers.length; i++) {
			let item = data.providers[i];
			if (item.analysisJustification) {
				table.push(
					<tr key={nRow} className={`${nRow % 2 === 0 ? 'bg-gray-50' : ''} hover:bg-slate-200 hover:shadow-md border-b border-gray-300`}>
						<td className='py-2 px-4'>{item.providerName}</td>
						<td className='py-2 px-4'>{item.recommendation}</td>
						<td className='py-2 px-4'>
							{item.analysisJustification.split(';').map((justification: any, jIndex: number) => (
								<div key={jIndex}>
									{justification} <br />
								</div>
							))}
						</td>
					</tr>
				);
				nRow++;
			}
		}
		return table;
	}

	const modalLCRows = () => {
		return (data?.calculationFactor.map((item: CalculationFactor, index: number) => (
			<tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : ''} hover:bg-slate-200 hover:shadow-md border-b border-gray-300`}>
				<td className="py-2 px-4">
					{item.description ?
						<Tooltip content={item.description.toUpperCase()}>
							<span className='pr-1'>{item.factorName}</span>
						</Tooltip> :
						<span className='pr-1'>{item.factorName}</span>}
				</td>
				<td className="py-2 px-4">{item.value}</td>
			</tr>
		)))
	}

	return (
		<PresentationModal
			id='detail-modal'
			show={true}
			onClose={() => navigate(-1)}
			header="Detalhes"
		>
			<Fragment>
				{isLoading ? <Loading /> :
					<div className='p-4'>
						<div className='grid grid-cols-8 text-sm gap-2'>
							<span className="col-span-1 font-bold">#:</span>
							<span className="col-span-7">{data?.referralId}</span>

							<span className="col-span-1 font-bold">CPF:</span>
							<span className="col-span-7">{data?.socialId}</span>
						</div>
						{data?.recommendationCode == "not-approved" || data?.recommendationCode == "pending" ?
							<Fragment>
								<div className="border border-gray-300 mt-4">
									<ATable
										headers={[{
											id: "modal-table4-column1",
											title: "Provider",
											cssClass: 'text-left pl-4',
											field: 'providerName',
											canSort: false,
										}, {
											id: "modal-table4-column2",
											title: 'Recomendação',
											cssClass: 'text-left pl-4',
											field: "recommendation",
											canSort: false,
										}, {
											id: "modal-table4-column3",
											title: 'Justificativa',
											cssClass: 'text-left pl-4',
											field: "analysisJustification",
											canSort: false,
										}]}
										autoResize={false} >
										{modalProviderRows4()}
									</ATable>
								</div>
							</Fragment> : null}

						<h2 className="font-bold mt-4 mb-2">Calculo do L.C.</h2>
						<div className="border border-gray-300">
							<ATable headers={[{
								id: "modal-table3-column1",
								title: "Atributo",
								cssClass: 'text-left pl-4',
								field: 'atributo',
								canSort: false,
							}, {
								id: "modal-table3-column2",
								cssClass: 'text-left pl-4',
								title: 'Valor',
								field: "valor",
								canSort: false,
							},]}
								autoResize={false} >
								{modalLCRows()}
							</ATable>
						</div>
					</div>
				}
			</Fragment>
		</PresentationModal>
	);
};

export default ReferralDetailModal;
// ValorNominal.ts

const _MS_PER_DAY = 1000 * 60 * 60 * 24;



export const NominalValue = {
  /**
   * Remove formatação monetária e converte a string para número.
   * @param value - Valor em formato monetário (ex: "R$ 1.234,56")
   * @returns Número convertido.
   */
  currencyConverter(value: string): number {
    // Remove o símbolo R$, pontos e substitui vírgula por ponto.
    value = String(value);
    value = value.replace(/R\$/g, "");
    value = value.replace(/\./g, ""); 
    value = value.replace(",", ".");
    value = value.trim();
    return Number(value);
  },

  /**
   * Converte uma string de data no formato "dd/mm/yyyy" para um objeto Date.
   * @param dateStr - Data no formato "dd/mm/yyyy".
   * @returns Data convertida.
   */
  dateConverter(dateStr: string): Date {
    const parts = dateStr.split("/");
    // É importante converter as partes para número
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  },

  /**
   * Calcula a diferença em dias entre duas datas, ignorando as informações de horário.
   * @param a - Data inicial.
   * @param b - Data final.
   * @returns Número de dias entre as duas datas.
   */
  dateDiffInDays(a: Date, b: Date): number {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  },

  /**
   * Realiza o cálculo progressivo com base no valor aprovado, taxa de juros, data de emissão e data de expiração.
   * @param valueApproved - Valor aprovado em formato monetário (string).
   * @param interestRate - Taxa de juros (por exemplo, 1.5 para 1,5%).
   * @param issuingDate - Data de emissão (objeto Date).
   * @param expirationDate - Data de expiração no formato "dd/mm/yyyy".
   * @returns Valor final presente (número).
   */
  progressiveCalculation(
    valueApproved: string,
    interestRate: number,
    issuingDate: Date,
    expirationDate: string
  ): number {
    const valorEmprestado = NominalValue.currencyConverter(valueApproved);

    const dataInicial: Date = issuingDate;
    const dataFinal: Date = NominalValue.dateConverter(expirationDate);
    const totalDays = NominalValue.dateDiffInDays(dataInicial, dataFinal);
    const taxa = interestRate;
    let valorFinalPresente = 0;
    const taxaTotal = (taxa / 100) + 1;
    const taxaExp = Math.pow(taxaTotal, 12);
    const taxaDiasExp = Math.pow(taxaExp, totalDays / 365);
    valorFinalPresente = valorEmprestado * taxaDiasExp;
    return valorFinalPresente;
  }
};

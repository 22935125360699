import PageSearch from '../../molecules/PageSearch';
import { useNavigate, useParams } from 'react-router-dom';
import PageList from '../../templates/PageList';
import ASearch from '../../organisms/ASearch';
import NewTooltip from '../../atoms/NewTooltip';
import { IoDocumentText } from "react-icons/io5";
import Tooltip from '../../atoms/Tooltip';
import React, { useState } from 'react';

type FarmerRecord = {
    id: number;
    name: string;
    socialId: string;
    email: string;
    lastPhoneNumberProvided: string;
    lastLoanOrderId: string;
}

const BASE_URL = `${process.env.REACT_APP_REFERRAL_API_URL}`;

function FarmerPageList() {

    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted, order: orderSorted, filter }: any = useParams();
    const [disabledSearch, setDisabledSearch] = useState(false)

    let [txtName, setTxtName] = useState('');
    let [txtSocialId, setTxtSocialId] = useState('');
    let [txtPhone, setTxtPhone] = useState('');
    let [txtEmail, setTxtEmail] = useState('');

    let paramName = '';
    let paramSociaId = '';
    let paramPhone = '';
    let paramEmail = '';

    if (filter)
        [paramName = '', paramSociaId = '',paramPhone='',paramEmail=''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));
    


     const url = `${BASE_URL}/loanorder/farmer-list?name=${paramName}&socialId=${paramSociaId}&email=${paramEmail}&phone=${paramPhone}`
         + `&page=${page}&size=${pageSize}&sort=${fieldSorted},${orderSorted}`;

    const renderRows = (rows: FarmerRecord[]) => {
        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={9} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhum registro encontrado...
                        </div>
                    </td>
                </tr>
            );
        }
        return rows.map((row: FarmerRecord, index: number) => (
            <tr key={row.id}
                className={`group ${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-4">
                    <Tooltip content={row.id} alignment='bottom'>
                        {row.id}
                    </Tooltip>
                </td>
                <td className="py-2 px-4 max-w-0 truncate">
                    <Tooltip content={row.name} alignment='bottom'>
                        <span className="block truncate">{row.name}</span>
                    </Tooltip>
                </td>
                <td className="py-2 px-4 max-w-0 truncate">
                    <Tooltip content={row.socialId} alignment='bottom'>
                        <span className="block truncate">{row.socialId}</span>
                    </Tooltip>
                </td>
                <td className="py-2 px-4 whitespace-nowrap">
                    <Tooltip content={row.email} alignment='bottom'>
                        {row.email}
                    </Tooltip>
                </td>
                <td className="py-2 px-4 whitespace-nowrap">
                    <Tooltip content={row.lastPhoneNumberProvided} alignment='bottom'>
                        {row.lastPhoneNumberProvided}
                    </Tooltip>
                </td>
                <td className="py-2 px-4">
                    <div className="opacity-0 group-hover:opacity-100 flex items-center transition-opacity duration-200">
                        <NewTooltip content="Ver registro">
                            <a
                                id={`link-alias-id-${row.id}`}
                                href={`/farmers/view/${row.lastLoanOrderId}`}
                                onClick={(e) => { e.preventDefault();navigate(`/farmers/view/${row.lastLoanOrderId}`); }}
                            >
                                <IoDocumentText className='ml-2 text-2xl text-blue-500' />
                            </a>
                        </NewTooltip>
                    </div>
                </td>
            </tr>
        ));
    };

  

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNavigate(page, pageSize, fieldSorted, orderSorted, txtName, txtSocialId,txtPhone,txtEmail), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtName, txtSocialId,txtPhone,txtEmail), { replace: true });
    };

    const pageSorted = (field: keyof FarmerRecord | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(0, pageSize, field, order, txtName, txtSocialId,txtPhone,txtEmail), { replace: true });
    };

    const nameSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, search, txtSocialId,txtPhone,txtEmail), { replace: true });
    };

    const emailSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtName, txtSocialId,txtPhone,search), { replace: true });
    };

    const phoneSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtName, txtSocialId,search,txtEmail), { replace: true });
    };

    const socialIdSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtName, search, txtPhone,txtEmail), { replace: true });
    };

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        name: string, socialId: string, phone: string, email: string) => {
        return `/loan-order/farmer-list/${page}/${pageSize}/${fieldSorted}/${orderSorted}/n=${name};s=${socialId};p=${phone};e=${email};`;
    };

    const onFilterClean = () => {
        setTxtName('');
        setTxtSocialId('');
        setTxtPhone('');
        setTxtEmail('');
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', '', '',''), { replace: true });
    }
    return (
        <PageList
            pageTitle="Lista de produtores com pedido real"
            url={url}
            breadcrumb={[{ label: 'Lista de produtores com pedido real', url: '#' }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            defaultBackendHeader={false}
            tableHeaders={[
                { id: "column-1", field: 'id', title: '#', canSort: false, width: "80px" },
                { id: "column-2", field: 'name', title: 'Nome', canSort: false },
                { id: "column-3", field: 'socialId', title: 'CPF', canSort: false },
                { id: "column-4", field: 'email', title: 'Email', canSort: false, width: "150px" },
                { id: "column-5", field: 'lastPhoneNumberProvided', title: 'Celular', canSort: false, width: "150px" },
                { id: "column-7", field: 'actions', title: 'Ações', canSort: false, width: "100px" },
            ]}
            itemsLabel='produtores'
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-4 gap-1">
                        <ASearch
                            id="src-name"
                            value={txtName}
                            onChange={setTxtName}
                            label="Procurar por nome..."
                            onSearch={nameSearch}
                        />
                        <ASearch
                            id='src-social-id'
                            value={txtSocialId}
                            onChange={setTxtSocialId}
                            label="procurar por CPF..."
                            onSearch={socialIdSearch}
                        />
                        <ASearch
                            id="src-email"
                            value={txtEmail}
                            onChange={setTxtEmail}
                            label="Procurar por email..."
                            onSearch={emailSearch}
                        />
                        <PageSearch
                                value={txtPhone}
                                disabled={disabledSearch}
                                label={`Procurar por celular...`}
                                onSearch={phoneSearch}
                                onChange={setTxtPhone}
                                filterCleanVisible={true}
                                filterCleanEnabled={(txtName || txtEmail || txtPhone || txtSocialId || txtPhone) ? true : false}
                                configureVisible={false}
                                onFilterClean={onFilterClean}
                        />
                    </div>
                ),
                controlSlot: (
                    <div className='pt-4' >
                      
                    </div>
                ),
            }}
        </PageList >
    );
};

export default FarmerPageList;
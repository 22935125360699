import { Outlet } from "react-router-dom";
import Loading from "../ions/Loading";
import PageHeader from "../molecules/PageHeader";
import { ReactNode, useEffect, useRef, useState } from "react";

interface BreadcrumbItem {
    label: string;
    url: string;
}

type PageFormProps = {
    pageTitle: string;
    apiVersion: string;
    isLoading: boolean;
    breadcrumb: BreadcrumbItem[];
    controlSlot: ReactNode;
    children: ReactNode;
}

const PageForm = ({ pageTitle, isLoading, breadcrumb, apiVersion, controlSlot, children }: PageFormProps) => {

    const [isScrolled, setIsScrolled] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (contentRef.current) {
                setIsScrolled(contentRef.current.scrollTop > 0);
            }
        };

        const contentElement = contentRef.current;
        if (contentElement) {
            contentElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (contentElement) {
                contentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <div className={`px-4 sticky top-0 z-10 bg-white ${isScrolled ? 'shadow-md' : ''}`}>
                {isLoading && <Loading />}
                <PageHeader
                    title={pageTitle}
                    apiVersion={apiVersion}
                    breadcrumb={breadcrumb}
                >
                    <div className="pt-4 w-full">
                        {controlSlot}
                    </div>
                </PageHeader>
            </div>

            <div ref={contentRef} className="flex-1 overflow-y-auto px-4">
                {children}
            </div>

            <Outlet />
        </div>
    );
};

export default PageForm;

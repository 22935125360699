import { useNavigate, useParams } from "react-router-dom";
import PresentationModal from "../../organisms/PresentationModal";
import useFetch from "../../../hooks/useFetch";
import { Fragment } from "react";
import Loading from "../../ions/Loading";

const BASE_FARMER_ANALYSIS_URL = `${process.env.REACT_APP_FARMER_ANALYSIS_API_URL}/farmer-analysis`;

const BiroVariablesModal = () => {

	const navigate = useNavigate();

	const { referralId, providerName } = useParams();

	const { isLoading, data, error, isTokenError } = useFetch(`${BASE_FARMER_ANALYSIS_URL}/${referralId}/variables/${providerName}`);

	if (isTokenError) {
		navigate("/denied");
	}

	if (error) {
		navigate("/network-error");
	}

	return (
		<PresentationModal
			id='biro-variables-modal'
			show={true}
			onClose={() => navigate(-1)}
			header={`Variáveis do birô: ${providerName}`}
		>
			<Fragment>
				{isLoading ? <Loading /> :
					<Fragment>
						<div className='p-4'>
							<div style={{ height: '500px', overflowY: 'auto' }}>
								<table className="table table-striped" style={{ width: '100%' }}>
									<tr style={{ textAlign: 'left' }}>
										<th className="py-2 px-4 border-b border-gray-300">Variável</th>
										<th className="py-2 px-4 border-b border-gray-300">Valor</th>
									</tr>
									{data ? (
										data.map((item: any) => (
											<tbody key={item.groupVariableName}>
												{item.variables ? item.variables.map((variableItem: any, index: number) => (
													<tr key={index}>
														<td className="py-2 px-4 border-b border-gray-300">{variableItem.alias}</td>
														<td className="py-2 px-4 border-b border-gray-300">{variableItem.calculatedValue}</td>
													</tr>
												)) : (
													null // TODO mensagem de valores vazios
												)}

												{item.groupVariableName ? (
													<tr>
														<td className="py-2 px-4 border-b border-gray-300"><b>{item.groupAlias}</b></td>
														<td className="py-2 px-4 border-b border-gray-300"><b>{item.groupComputedValue}</b></td>
													</tr>
												) : (
													null
												)}
											</tbody>
										))
									) : (
										null
									)}
								</table>
							</div>
						</div>
					</Fragment>
				}
			</Fragment>
		</PresentationModal>
	);
}

export default BiroVariablesModal;
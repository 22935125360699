import { FC, useState, useEffect, ReactNode } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FaArrowsUpDown } from 'react-icons/fa6';
import "./ATable.css";
import NewTooltip from '../atoms/NewTooltip';

export interface ATableHeader {
    id: string;
    title: string;
    class?: string;
    field?: string;
    width?: string;
    canSort: boolean;
    sorted?: string;
    headerTooltipDescription?: string;
    cssClass?: string;
}

interface TableProps {
    headers: ATableHeader[];
    fieldSorted?: string;
    orderSorted?: 'ASC' | 'DESC';
    children: ReactNode;
    onSort?: (field: string, order: 'ASC' | 'DESC') => void;
    autoResize?: boolean;
    marginTop?: number;
    onSelect?: (checked: boolean) => void;
    canSelectAll?: boolean;
}

interface DragState {
    iniMouse: number;
    iniSize: number;
    row: number;
}

const MARGIN_TOP_DEFAULT = 176;

const ATable: FC<TableProps> = ({ headers: originHeaders, fieldSorted, orderSorted, children, onSort, autoResize, marginTop = MARGIN_TOP_DEFAULT,
    onSelect, canSelectAll = false }) => {
    const field = fieldSorted ? fieldSorted : 'id';
    const order = orderSorted ? orderSorted : 'ASC';

    let headers = originHeaders;

    if (canSelectAll) {
        headers = [{
            id: 'select-all',
            title: '',
            canSort: false,
            width: '30px',
            cssClass: 'w-[30px]',
        }, ...originHeaders];
    }

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const calculateWindowHeight = () => {
        setWindowHeight(window.innerHeight - marginTop);
    };

    useEffect(() => {
        if (autoResize) {
            calculateWindowHeight();
            window.addEventListener('resize', calculateWindowHeight);

            return () => {
                window.removeEventListener('resize', calculateWindowHeight);
            };
        }
    }, []);

    const makeSort = (fieldSorted: string) => {
        let orderValue: 'ASC' | 'DESC' = 'ASC';
        if (fieldSorted === field) {
            orderValue = order === 'ASC' ? 'DESC' : 'ASC';
        }
        if (onSort) {
            onSort(fieldSorted, orderValue);
        }
    };

    return (
        <div className={autoResize ? "relative overflow-x-auto border" : ''} style={autoResize ? { height: windowHeight } : {}}>
            <table className="min-w-full text-sm table-auto auto-cols-auto" >
                <thead className='text-xs text-slate-500 uppercase bg-gray-50 sticky top-0  shadow-md' style={{ zIndex: 1 }}>
                    <tr>
                        {headers.map((header, row) => (
                            <th
                                id={`header-${row}`}
                                key={`row-${row}`}
                                className={`${header.cssClass ? header.cssClass : 'border-b text-center w-fit'} h-10`}
                                style={{ width: header.width}}
                            >
                                {canSelectAll && row === 0 ? (
                                    <input
                                        type="checkbox"
                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 focus:ring-2 px-2 py-2'
                                        onChange={(e) => {
                                            if (onSelect) {
                                                onSelect(e.target.checked);
                                            }
                                        }}
                                    />
                                ) : header.canSort ? (
                                    <a className="hover:text-teal-500 hover:cursor-pointer"
                                        onClick={() => makeSort(header.field || '')}>
                                        <div className='flex justify-center items-center'>
                                            {header.headerTooltipDescription
                                                ?
                                                <NewTooltip content={header.headerTooltipDescription} alignment='bottom'>
                                                    <span className={`${header.field === field ? 'text-teal-600' : ''} pr-1`}>{header.title}</span>
                                                </NewTooltip>
                                                :
                                                <span className={`${header.field === field ? 'text-teal-600' : ''} pr-1`}>{header.title}</span>
                                            }
                                            {order === 'DESC' && header.field === field && (
                                                <MdKeyboardArrowUp className="w-5 h-5 text-teal-700" />
                                            )}
                                            {order === 'ASC' && header.field === field && (
                                                <MdKeyboardArrowDown className="w-5 h-5 text-teal-700" />
                                            )}
                                            {header.field !== field && <FaArrowsUpDown className="w-3 h-3" />}
                                        </div>
                                    </a>
                                ) : (
                                    header.headerTooltipDescription ?
                                        <NewTooltip content={header.headerTooltipDescription} alignment='bottom'>
                                            {header.title}
                                        </NewTooltip> :
                                        <span>{header.title}</span>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
};

export default ATable;
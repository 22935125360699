import { FC, ReactNode } from 'react';
import NewTooltip from './NewTooltip';

import { LuBadgeDollarSign } from "react-icons/lu";
import { BsBuilding } from "react-icons/bs";

import { TbLetterA, TbLetterV } from "react-icons/tb";

interface IconRegistrationProfileProps {
  profile: string;
}

const IconRegistrationProfile: FC<IconRegistrationProfileProps> = ({ profile }) => {

  const icons: { [key: string]: ReactNode } = {
    "ISSUER": <BsBuilding className='text-xl' />,
    "RECIPIENT": <LuBadgeDollarSign className='text-xl' />,
    "GUARANTOR-RETAIL": <TbLetterV className='text-xl' />,
    "GUARANTOR-WHOLESALE": <TbLetterA className='text-xl' />,
  };

  const tooltips: { [key: string]: string } = {
    "ISSUER": "Emitente",
    "RECIPIENT": "Beneficiário",
    "GUARANTOR-RETAIL": "Avalista Varejo",
    "GUARANTOR-WHOLESALE": "Avalista Atacado",
  };

  return (
    <NewTooltip content={tooltips[profile]}>
      {icons[profile]}
    </NewTooltip>
  );
};

export default IconRegistrationProfile;
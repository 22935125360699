import React, { InputHTMLAttributes, useEffect, useState, forwardRef, ReactNode } from 'react';
import InputFrame from '../atoms/InputFrame';

interface CurrencyInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'prefix'> {
    label: string;
    errorMessage?: string;
    prefix?: ReactNode;
    onChange?: (value: number) => void;
    fractionDigits?: number;
    textColor?: string;
    maxValue?: number;
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
    ({
        label,
        errorMessage,
        className,
        prefix,
        value = 0,
        onChange,
        fractionDigits = 0,
        textColor = "text-gray-900",
        disabled = false,
        maxValue,
        ...restProps
    }, ref) => {

        const numberValue = Number(value);

        const [displayValue, setDisplayValue] = useState(formatCurrency(numberValue));
        const [previousValue, setPreviousValue] = useState(numberValue); // Armazena o valor anterior

        useEffect(() => {
            setDisplayValue(formatCurrency(numberValue));
            setPreviousValue(numberValue); // Atualiza o valor anterior quando 'value' muda
        }, [value]);

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let numericValue = e.target.value.replace(/[^0-9]/g, '');
            let parsedValue = Number(numericValue) / Math.pow(10, fractionDigits);
            

            if (maxValue !== undefined && parsedValue > maxValue) {
                // Se exceder o limite, reverte para o valor anterior
                setDisplayValue(formatCurrency(previousValue));
                if (onChange) {
                    onChange(previousValue);
                }
                return; // Impede a atualização do estado
            }

            const formattedValue = formatCurrency(parsedValue);

            setDisplayValue(formattedValue);
            setPreviousValue(parsedValue); // Atualiza o valor anterior
            if (onChange) {
                onChange(parsedValue);
            }
        };

        function formatCurrency(value: number): string {
            if (fractionDigits !== 2) {
                return value.toFixed(fractionDigits).replace('.', ',');
            }
            return value.toLocaleString('pt-BR', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });
        }

        let focusColor = "focus:border-blue-600";
        let peerFocusColor = "peer-focus:text-blue-600";

        if (errorMessage) {
            textColor = "text-red-700";
            focusColor = "focus:border-red-600";
            peerFocusColor = "peer-focus:text-red-600";
        }

        if (disabled) {
            textColor = "text-gray-500 cursor-not-allowed font-medium";
            focusColor = "";
            peerFocusColor = "";
        }

        return (
            <InputFrame
                label={label}
                errorMessage={errorMessage}
                prefix={prefix}
                className="mb-4"
                textColor={textColor}
            >
                <input
                    type="text"
                    ref={ref}
                    value={displayValue}
                    onChange={handleInputChange}
                    disabled={disabled}
                    className={`block py-2.5 px-0 w-full text-sm ${textColor} bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 ${focusColor} peer`}
                    {...restProps}
                />
            </InputFrame>
        );
    }
);

CurrencyInput.displayName = 'CurrencyInput';

export default CurrencyInput;
import React, { TextareaHTMLAttributes } from 'react';
import InputFrame from '../atoms/InputFrame';

interface TextAreaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'prefix'> {
    label: string;
    errorMessage?: string;
    textColor?: string;
    rows?: number;
    prefix?: React.ReactNode;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({
        id,
        label,
        errorMessage,
        className,
        value,
        prefix,
        textColor = "text-gray-900",
        disabled = false,
        placeholder = "",
        rows = 3,
        ...restProps
    }, ref) => {

        let focusColor = "focus:border-blue-600";
        let peerFocusColor = "peer-focus:text-blue-600";

        if (errorMessage) {
            textColor = "text-red-700";
            focusColor = "focus:border-red-600";
            peerFocusColor = "peer-focus:text-red-600";
        }

        if (disabled) {
            textColor = "text-gray-500 cursor-not-allowed font-medium";
            focusColor = "";
            peerFocusColor = "";
        }

        return (
            <InputFrame
                label={label}
                errorMessage={errorMessage}
                prefix={prefix}
                className="mb-4"
                textColor={textColor}
            >
                <textarea id={id} rows={rows} ref={ref}
                    value={value}
                    className={`block p-2.5 w-full text-sm ${textColor} mt-3 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500`}
                    placeholder={placeholder}
                    {...restProps}
                ></textarea>
            </InputFrame>
        );
    }
);

export default TextArea;
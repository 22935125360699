import React from 'react';


const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

const modalStyle = {
  background: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '300px',
  textAlign: 'center',
};

const overlayStyle = {

};

interface ConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  id: string | number; // ou outro tipo que seja apropriado
  text: string;
}

const ConfirmationModal : React.FC<ConfirmationModalProps> = ({ isOpen, onCancel, onConfirm,title,id,text }) => {
  if (!isOpen) return null; // Se não estiver aberto, não renderiza nada
  
  return (
    <div style={
        {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 10000,
        }
    }>
      <div style={
        {
          background: '#fff',
          padding: '20px',
          borderRadius: '8px',
          width: '450px',
          textAlign: 'center'
        }
      }>
        <p>{text}</p>
        <div style={buttonContainerStyle}>
          <button
     
            className='bg-gray-300   text-white px-4 py-2 rounded-md hover:bg-gray-400'
            onClick={onCancel}
          >
            Cancelar
          </button>
          <button
         
            className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
            onClick={onConfirm}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

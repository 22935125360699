import { FC, useState } from "react";

type DateInputProps = {
    label: string;
    onChange: (e: any) => void;
}

const DateInput: FC<DateInputProps> = ({ label, onChange }) => {
    const [date, setDate] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let dateHandle = new Date(event.target.value);
        setDate(dateHandle.toISOString().split("T")[0]);
        onChange(dateHandle.toISOString().split("T")[0]);
    };

    return (
        <div>
            <label htmlFor="date">{label}</label>
            <input
                type="date"
                id="date"
                value={date}
                onChange={handleChange}
                className="border p-2 rounded"
            />
        </div>
    );
};

export default DateInput;
import PageSearch from '../../molecules/PageSearch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageList from '../../templates/PageList';
import ASearch from '../../organisms/ASearch';
import NewTooltip from '../../atoms/NewTooltip';
import { IoDocumentText } from "react-icons/io5";
import IconRegistrationStatus from '../../atoms/IconRegistrationStatus';
import IconRegistrationProfile from '../../atoms/IconRegistrationProfile';
import Tooltip from '../../atoms/Tooltip';
import { TiEdit } from 'react-icons/ti';


type Partner = {
    id: number;
    uuid: string;
    fantasyName: string;
    legalName: string;
    govTaxIdFormatted: string;
    profiles: string[];
    statusId: number;
    status: string;

    canEdit: boolean;
    canChangeProfile: boolean;
}

const profileOrder = {
    "ISSUER": 1,
    "GUARANTOR-RETAIL": 2,
    "GUARANTOR-WHOLESALE": 3,
    "RECIPIENT": 4,
};

const BASE_URL = `${process.env.REACT_APP_REGISTRATION_API_URL}/partners`;

function PartnerPageList() {

    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted, order: orderSorted, filter }: any = useParams();

    let txtPartnerName = '';
    let txtGovTaxId = '';

    if (filter)
        [txtPartnerName = '', txtGovTaxId = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

    const url = `${BASE_URL}/page-list?legalName=${txtPartnerName}&govTaxId=${txtGovTaxId}`
        + `&page=${page}&size=${pageSize}&sort=${fieldSorted},${orderSorted}`;

    const renderRows = (rows: Partner[]) => {
        return rows.map((row: Partner, index: number) => (
            <tr key={row.id}
                className={`group ${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-4">{row.id}</td>
                <td className="py-2 px-4 max-w-0 truncate">
                    <Tooltip content={row.legalName} alignment='bottom'>
                        <span className="block truncate">{row.legalName}</span>
                    </Tooltip>
                </td>
                <td className="py-2 px-4 max-w-0 truncate">
                    <Tooltip content={row.fantasyName} alignment='bottom'>
                        <span className="block truncate">{row.fantasyName}</span>
                    </Tooltip>
                </td>
                <td className="py-2 px-4 whitespace-nowrap">{row.govTaxIdFormatted}</td>
                <td className="py-2 px-4">
                    {row.profiles
                        .slice()
                        .sort((a: string, b: string) => profileOrder[a as keyof typeof profileOrder] - profileOrder[b as keyof typeof profileOrder])
                        .map((profile: string, profIndex: number) => (
                            <span className='mr-2' key={`span-${index}-rp-${profIndex}`}>
                                <IconRegistrationProfile key={`icon-${index}-rp-${profIndex}`} profile={profile} />
                            </span>
                        ))}
                </td>
                <td className="py-2 px-4 text-center">
                    <IconRegistrationStatus statusId={row.statusId} status={row.status} />
                </td>
                <td className="py-2 px-4">
                    <div className="opacity-0 group-hover:opacity-100 flex items-center transition-opacity duration-200">
                        {(row.canEdit) && (
                            <NewTooltip content="Ver/editar registro">
                                <a
                                    id={`btn-copy-${row.uuid}`}
                                    href={`/form/${row.uuid}`}
                                    onClick={(e) => { e.preventDefault(); alert('Ação de editar registro'); }}
                                >
                                    <TiEdit className='text-2xl text-2xl text-blue-500' />
                                </a>
                            </NewTooltip>
                        )}

                        {(row.canChangeProfile) && (
                            <NewTooltip content="Cadastrar novo perfil">
                                <Link
                                    id={`link-alias-id-${row.id}`}
                                    to={`profiles?profiles=${row.profiles}`}
                                >
                                    <IoDocumentText className='ml-2 text-2xl text-blue-500' />
                                </Link>
                            </NewTooltip>
                        )}
                    </div>
                </td>
            </tr>
        ));
    };

    const onFilterClean = () => {
        txtPartnerName = '';
        txtGovTaxId = '';
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtPartnerName, txtGovTaxId), { replace: true });
    };

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNavigate(page, pageSize, fieldSorted, orderSorted, txtPartnerName, txtGovTaxId), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtPartnerName, txtGovTaxId), { replace: true });
    };

    const pageSorted = (field: keyof Partner | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(0, pageSize, field, order, txtPartnerName, txtGovTaxId), { replace: true });
    };

    const partnerNameSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, search, txtGovTaxId), { replace: true });
    };

    const govTaxIdSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtPartnerName, search), { replace: true });
    };

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        partnerName: string, govTaxId: string) => {
        const encodedGovTaxId = encodeURIComponent(govTaxId);
        return `/registration/partners/${page}/${pageSize}/${fieldSorted}/${orderSorted}/p=${partnerName};r=${encodedGovTaxId};`;
    };

    return (

        <PageList
            pageTitle="Listagem de parceiras"
            url={url}
            breadcrumb={[{ label: 'Listagem de parceiras', url: '#' }]}
            fetchHeaders={[{ key: 'x-api-key', value: `${process.env.REACT_APP_REGISTRATION_API_KEY}` }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            defaultBackendHeader={false}
            tableHeaders={[
                { id: "column-1", field: 'id', title: '#', canSort: true, width: "80px" },
                { id: "column-2", field: 'legalName', title: 'Parceira', canSort: true },
                { id: "column-3", field: 'fantasyName', title: 'Nome', canSort: true },
                { id: "column-4", field: 'govTaxId', title: 'CNPJ', canSort: true, width: "150px" },
                { id: "column-5", field: 'profiles', title: 'Perfis', canSort: false, width: "150px" },
                { id: "column-6", field: 'status', title: 'Status', canSort: false, width: "140px" },
                { id: "column-7", field: 'actions', title: 'Ações', canSort: false, width: "100px" },
            ]}
            itemsLabel='parceiras'
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-2 gap-1">
                        <ASearch
                            id="src-partner-name"
                            value={txtPartnerName}
                            label="Procurar por parceira..."
                            onSearch={partnerNameSearch}
                        />
                        <PageSearch
                            value={txtGovTaxId}
                            label="Procurar por CNPJ..."
                            onSearch={govTaxIdSearch}
                            filterCleanVisible={true}
                            filterCleanEnabled={(txtGovTaxId || txtPartnerName) ? true : false}
                            configureVisible={false}
                            onFilterClean={onFilterClean}
                        />
                    </div>
                ),
                controlSlot: (
                    <div className='pt-2' >
                        <NewTooltip content='Cadastrar nova parceira' alignment='left'>
                            <Link to='profiles'
                                className='text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center'
                            >
                                Novo cadastro
                            </Link>
                        </NewTooltip>
                    </div>
                ),
            }}
        </PageList >
    );
};

export default PartnerPageList;
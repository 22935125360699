import React, { FC } from "react";

type ActionButtonProps = {
    type?: 'button' | 'submit';
    className?: string;
    label: React.ReactNode;
    onClick?: () => void;
}

const ActionButton: FC<ActionButtonProps> = ({ type = 'button', className, label, onClick }) => {

    if (!className) {
        className = 'bg-teal-500 hover:bg-teal-700';
    }


    return (
        <div>

            <button
                type={type}
                className={`text-white py-2 px-4 rounded-lg ${className}`}
                onClick={onClick}
            >
                {label}
            </button>
        </div>
    );
}
export default ActionButton
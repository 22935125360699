import { useSignal } from "@preact/signals-react";
import PageForm from "../../templates/PageForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import TextEdit from "../../molecules/TextEdit";
import TextArea from "../../molecules/TextArea";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { ErrorField } from '../../../types/ErrorField';
import { getErrorMessage} from '../../../utils/formErrorUtils';
import ATable from "../../organisms/ATable";
import NewTooltip from '../../atoms/NewTooltip';

import useFetch from "../../../hooks/useFetch";


const REFERRAL_API_URL = `${process.env.REACT_APP_REFERRAL_API_URL}`;
const LIST_PAGE = "referrals/farmer-analysis/0/100/referral.id/ASC";


type LoanOrderRecord = {
    loanOrderUUID: string,
    loanOrderId: string,
    status: string,
    score: string,
    date: string,
    recommendation: string,
    approvedLimit: string,
    analysisDate: string,
    observation: string,
    partnerName: string,
    guarantorName: string,
    farmerEmail: string,
    farmerName: string,
    maritalState: string,
    nationality: string,
    phone: string,
    socialId: string,
    idCard: string,
    address: string,
    city: string,
    getPostalCode: string,
    addresNumber: string,
    addressComplement: string,
    neighborhood: string,
    cropType: string,
}

type  DateLastSeen = {
    Year: string,
    Month: string,
    Day: string
}

type Address = {
    City: string,
    PostalCode: string,
    Number: string,
    Neighborhood: string,
    State: string,
    Street :string,
    DateLastSeen: DateLastSeen,
}

const FarmerDataViewPage: React.FC = () => {

    const navigate = useNavigate();
    const { loanOrderId = "" } = useParams();
    const { data, isLoading, error, isTokenError } = useFetch(`${REFERRAL_API_URL}/loanorder/view/${loanOrderId}`);
    const REACT_APP_GEOLOCATION_API_URL = process.env.REACT_APP_GEOLOCATION_API_URL;
    const REACT_APP_GEOLOCATION_API_KEY = process.env.REACT_APP_GEOLOCATION_API_KEY;

    const [farmerCity,setFarmerCity]=useState('');
    const [provinceName,setProvinceName]=useState('');
    const [quodPhoneNumbers,setQuodPhoneNumbers]=useState('');
    const [quodEmails,setQuodEmails]=useState('');
    const [addressList,setAddressList]=useState<Address[]>([]);
    const errorFields = useSignal<ErrorField[]>([]);

    const columns = [ 
    {
        id: "column1",
        title: 'Cidade/Estado',
        width: '30%',
        field: "name",
        canSort: false
    },{
        id: "column2",
        title: 'Endereço',
        width: '30%',
        field: "name",
        canSort: false
    }, {
        id: "column3",
        title: 'Numero',
        canSort: false
    }, {
        id: "column4",
        title: 'Bairro',
        canSort: false
    }, {
        id: "column5",
        title: 'CEP',
        canSort: false
    },{
        id: "column6",
        title:"Última vez visto pela Quod",
        field: "",
        canSort: false
    }];

    
    let loanOrder=useSignal<LoanOrderRecord>({
        loanOrderUUID: '',
        loanOrderId: '',
        status: '',
        score: '',
        date: '',
        recommendation: '',
        approvedLimit: '',
        analysisDate: '',
        observation: '',
        partnerName: '',
        guarantorName: '',
        farmerEmail: '',
        farmerName: '',
        maritalState: '',
        nationality: '',
        phone: '',
        socialId: '',
        idCard: '',
        address: '',
        city: '',
        getPostalCode: '',
        addresNumber: '',
        addressComplement: '',
        cropType: '',
        neighborhood: ""
    })

    function compareDates(a: DateLastSeen, b: DateLastSeen): number {
        if (a.Year !== b.Year) {
          return parseInt(b.Year) - parseInt(a.Year); // Ordena por Year (decrescente)
        }
        if (a.Month !== b.Month) {
          return parseInt(b.Month) - parseInt(a.Month); // Ordena por Month (decrescente)
        }
        return parseInt(b.Day) - parseInt(a.Day); // Ordena por Day (decrescente)
      }

    useEffect(() => {
        if (data) {
            loanOrder.value = data;
            fetchCity();
            fetchQuodDocument();
            isLoadingForm.value = false;
        }
    }, [data]);


    const isLoadingForm = useSignal(false);
    const apiVersion = useRef('');
   
    const handleBack = () =>{
        navigate(-1);
    };

    const fetchQuodDocument = async () => {
        try {
            isLoadingForm.value = true;
            const token = await Auth.currentSession().then((user) => user.getIdToken().getJwtToken());
            const response = await fetch(REFERRAL_API_URL+'/referrals/' + loanOrder.value.loanOrderId+"/documents/Quod",{
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
            }
        });
            const responseData = await response.json();
            let mobilePhoneList = [];
            let phoneNumberList = [];
            if(responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.PhoneNumberHistory!=null)
                phoneNumberList = responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.PhoneNumberHistory.PhoneNumber;
            
            if(responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.MobilePhoneNumberHistory!=null)
                mobilePhoneList = responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.MobilePhoneNumberHistory.MobilePhoneNumber;
            
            var mobilePhone = "";
            type PhoneNumber ={
                DateLastSeen: DateLastSeen,
                PhoneNumber: string
            }

            phoneNumberList.forEach( (element:PhoneNumber) => {
                mobilePhone += element.PhoneNumber+"("+element.DateLastSeen.Day+"/"+element.DateLastSeen.Month+"/"+element.DateLastSeen.Year+")  ";
            });

            mobilePhoneList.forEach( (element:PhoneNumber) => {
                mobilePhone += element.PhoneNumber+"("+element.DateLastSeen.Day+"/"+element.DateLastSeen.Month+"/"+element.DateLastSeen.Year+")  ";
            });
            setQuodPhoneNumbers(mobilePhone);
            const errors: Array<ErrorField> = [];
            //replace(/\D/g, "") remove all non numeric characters
            if(!mobilePhone.includes(loanOrder.value.phone.replace(/\D/g, ""))){
                const error: ErrorField = { fieldName: '', message: '' };
                error.fieldName = "phone";
                error.message = "Telefone não encontrado na Quod";
                errors.push(error);
            }

            type EmailRecord ={
                DateLastSeen: DateLastSeen,
                Email: string
            }
            let emailList = [];
            if(responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.EmailHistory!=null)
                emailList=responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.EmailHistory.Email;

            let emails = " ";
            emailList.sort((a: EmailRecord, b: EmailRecord) => compareDates(a.DateLastSeen, b.DateLastSeen));
            emailList.forEach( (element:EmailRecord) => {
                emails += element.Email+"("+element.DateLastSeen.Day+"/"+element.DateLastSeen.Month+"/"+element.DateLastSeen.Year+")  ";
            });
            setQuodEmails(emails);

            if(!emails.toLowerCase().includes(loanOrder.value.farmerEmail.toLowerCase())){
                const error: ErrorField = { fieldName: '', message: '' };
                error.fieldName = "email";
                error.message = "Email não encontrado na Quod";
                errors.push(error);
            }
            
            let addressList = [];
            if(responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.AddressHistory!=null)
                addressList=responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.AddressHistory.Address;    

            addressList.sort((a: Address, b: Address) => compareDates(a.DateLastSeen, b.DateLastSeen));
            let addressToVerify ="";
            addressList.forEach((element: Address) => {
                addressToVerify += `${element.Street ?? ''}${element.Number ?? ''}${element.Neighborhood ?? ''}`;
            });
            addressToVerify = addressToVerify.replace(/\s+/g, "").toLowerCase();
            const addressLoanOrder = (loanOrder.value.address+loanOrder.value.addresNumber+loanOrder.value.neighborhood).replace(/\s+/g, "").toLowerCase();
            if(!addressToVerify.includes(addressLoanOrder)){
                const error: ErrorField = { fieldName: '', message: '' };
                error.fieldName = "address";
                error.message = "Endereço informado não encontrado na Quod";
                errors.push(error);
            }

            setAddressList(responseData[0].document.QuodReportResponseEx.Response.Records.Record[0].BestInfo.AddressHistory.Address);
            errorFields.value = errors ? errors: [];
        }catch (error) {
            toast.error('Erro ao carregar dados da Quod');
            isLoadingForm.value = false;
        }
    };


    const renderRows = (rows: Address[]) => {
        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={9} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhum registro encontrado...
                        </div>
                    </td>
                </tr>
            );
        }
        return rows.map((row: Address, index) => (
            <tr 
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}
            
            >
                <td className="py-2 px-4 border-b border-gray-300 "
                    style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px'}}
                >
                    <div className="truncate">{row.City}/{row.State}</div>
                </td>

                <td className="py-2 px-4 border-b border-gray-300 "
                     style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px'}}
                >
                    <NewTooltip content={row.Street} alignment={'right'}>
                        <div className="truncate">{row.Street}</div>
                    </NewTooltip>
                </td>
                <td className="py-2 px-4 border-b border-gray-300 ">{row.Number}</td>
                <td className="py-2 px-4 border-b border-gray-300">{row.Neighborhood}</td>
                <td className="py-2 px-4 border-b border-gray-300">{row.PostalCode}</td>
                <td className="py-2 px-4 border-b border-gray-300">{row.DateLastSeen.Day}/{row.DateLastSeen.Month}/{row.DateLastSeen.Year}</td>
            </tr>
        ));
    };

    const fetchCity = async () => {
        try {
            isLoadingForm.value = true;
            const response = await fetch(REACT_APP_GEOLOCATION_API_URL+'/cities/' + loanOrder.value.city, {
                headers: {
                    'x-api-key': REACT_APP_GEOLOCATION_API_KEY || ''
                }
            });
            const responseData = await response.json();
            setFarmerCity(responseData.name);
            setProvinceName(responseData.province.name);
        } catch (error) {
            toast.error('Erro ao carregar dados de cidade e estado');
            isLoadingForm.value = false;
        }
    };

    return (
        <PageForm
            pageTitle="Registro do produtor"
            apiVersion={apiVersion.current}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={[{ label: 'Pedido de emprestimo', url: LIST_PAGE }, { label: 'Visualização de registro', url: '#' }]}
            controlSlot={(
                    <div className="pt-4 flex justify-end">
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded ml-2"
                            onClick={handleBack}
                        >
                            Voltar
                        </button>
                    </div>
            )}
        >

                    <div className="container mx-auto px-2 py-4">

                        <div className="border-b border-gray-900/10 pb-2">
                                <h2 className="text-base/7 font-semibold text-gray-900">Produtor</h2>
                                <p className="mt-1 text-sm/6 text-gray-600">Dados referentes ao produtor e dados da Quod do ultimo pedido real recebido</p>
                        </div>

                        <div className="grid grid-cols-6 gap-4 mt-4">
                            <div className="col-span-4">
                                <TextEdit
                                    label="Nome"
                                    value={loanOrder.value.farmerName}
                                    disabled={true}
                                />
                            </div>

                            <div className="col-span-2">
                                <TextEdit
                                    label="Estado civil"
                                    value={loanOrder.value.maritalState}
                                    disabled={true}
                                />
                            </div>

                            <div className="col-end-0 col-span-4">
                                <TextEdit
                                    label="Nacionalidade"
                                    value={loanOrder.value.nationality}
                                    disabled={true}
                                />
                            </div>
                            </div>
                            
                            <div className="grid grid-cols-6 gap-4 mt-4">

                                <div className="col-span-2">
                                    <TextEdit
                                        label="Celular informado"
                                        value={loanOrder.value.phone}
                                        disabled={true}
                                        errorMessage={getErrorMessage(errorFields.value, "phone")}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-6 gap-4 mt-4">
                            <div className="col-span-6">
                                <TextArea
                                    label="Celular(es) encontrado(s) na Quod - (Telefone e data de última vez que foi obtido pela Quod)"
                                    value={quodPhoneNumbers}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-6 gap-4 mt-4">
                            <div className="col-span-2">
                                <TextEdit
                                    label="Email"
                                    value={loanOrder.value.farmerEmail}
                                    disabled={true}
                                    errorMessage={getErrorMessage(errorFields.value, "email")}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-6 gap-4 mt-4">
                            <div className="col-span-6">
                                <TextArea
                                    label="Email(s) encontrado(s) na Quod - (Email e data de última vez que foi obtido pela Quod)"
                                    value={quodEmails}
                                    
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-6 gap-4 mt-4">
                            <div className="col-span-2">
                                <TextEdit
                                    label="CPF"
                                    value={loanOrder.value.socialId}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextEdit
                                    label="RG"
                                    value={loanOrder.value.idCard}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-6 gap-4 mt-4">
                            <div className="col-span-4">
                                <TextEdit
                                    label="Endereço"
                                    value={loanOrder.value.address}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextEdit
                                    label="Numero"
                                    value={loanOrder.value.addresNumber}
                                />
                            </div>

                            <div className="col-span-4">
                                <TextEdit
                                    label="Bairro"
                                    value={loanOrder.value.neighborhood}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextEdit
                                    label="CEP"
                                    value={loanOrder.value.getPostalCode}
                                />
                            </div>

                            <div className="col-span-4">
                                <TextEdit
                                    label="Cidade"
                                    value={farmerCity}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextEdit
                                    label="Estado(UF)"
                                    value={provinceName}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextEdit
                                    label="Complemento"
                                    value={loanOrder.value.addressComplement}
                                />
                            </div>
                            
                        </div>


                        <div className="grid grid-cols-1 gap-4 mt-4">
                            <div className="border-b border-gray-900/10 pb-2">
                                <h2 className="text-base/7 font-semibold text-gray-900">Histórico de endereços encontrados na Quod</h2>
                            </div>
                            <div>
                                { getErrorMessage(errorFields.value, "address")?(
                                    <div className="text-red-500">{getErrorMessage(errorFields.value, "address")}</div>
                                ):(
                                    <div></div>
                                )
                                 }
                                <ATable 
                                    headers={columns}>
                                    {renderRows(addressList)}
                                 </ATable>
                            </div>
                        </div>

                    </div>
        </PageForm >
    );

};

export default FarmerDataViewPage;
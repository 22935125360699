import { FC, ReactNode } from 'react';
import NewTooltip from './NewTooltip';
import { FaCopy, FaRegHandshake, FaRegStar } from 'react-icons/fa';
import { MdEditDocument } from 'react-icons/md';
import { LuBadgeCheck } from "react-icons/lu";
import { FaScaleBalanced } from 'react-icons/fa6';
import { FcCancel } from 'react-icons/fc';


interface IconRegistrationStatusProps {
  statusId: number;
  status: string;
}

const IconRegistrationStatus: FC<IconRegistrationStatusProps> = ({ statusId, status }) => {

  const icons: { [key: number]: ReactNode } = {
    0: <MdEditDocument className='text-xl' />, // Em edição
    1: <FaRegStar className='text-xl text-blue-500' />, // Novo
    2: <FaRegHandshake className='text-xl text-amber-500' />,
    3: <FaScaleBalanced className='text-xl text-yellow-500' />, // Em análise jurídica
    4: <LuBadgeCheck className='text-xl text-green-600' />, // Ativo
    5: <FcCancel className='text-xl' />, // Inativo
    6: <FaCopy className='text-xl' />,
  };

  return (
    <NewTooltip content={status}>
      {icons[statusId]}
    </NewTooltip>
  );
};

export default IconRegistrationStatus;

import React, { useState, useCallback, useEffect } from 'react';
import NewTooltip from '../../atoms/NewTooltip';

import Tooltip from '../../atoms/Tooltip';

import PageSearch from '../../molecules/PageSearch';
import { useNavigate, useParams } from 'react-router-dom';
import ASearch from '../../organisms/ASearch';

import PageList from '../../templates/PageList';
import MenuButton from '../../atoms/MenuButton';
import ConfirmationModal from '../../templates/ConfirmationModal';
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { RiArrowGoBackFill } from 'react-icons/ri';
import { IoDocumentText } from "react-icons/io5";




type ReferraLoanOrderRejected = {
    id: string;
    referralUUID: string;
    farmerName: string;
    createdAt: string;
    socialId: string;
    partnerOriginName: string;
    guarantorName: string;
    userName: string;
    statusBeforeAnalysis: string;
    analysisType: string;
};

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/loan-order-analysis/rejected`;
const REFERRAL_URL_REVERT = `${process.env.REACT_APP_REFERRAL_API_URL}/loan-order-analysis/rejected/revert`;



const ReferralLoanOrderRejectedListPage = () => {
    const navigate = useNavigate();
    const [isLoadingData, setIsLoadingData] = useState(false);

    let [isModalOpen, setIsModalOpen] = useState(false);
    const [revertOrderId,setRevertOrderId] = useState('');
    let { page, pageSize, field: fieldSorted, order: orderSorted, id, socialId, filter }: any = useParams();

    let [txtId, setTxtId] = useState('');
    let [txtSocialId, setTxtSocialId] = useState('');
    let [txtName, setTxtName] = useState('');
    
    let paramId = '';
    let paramSocialId = '';
    let paramName = '';

    if (filter)
        [paramId = '', paramSocialId = '', paramName = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

  

    const url = `${BASE_REFERRAL_URL}?name=${paramName}&socialId=${paramSocialId}&page=${page}&pageSize=${pageSize}&id=${paramId}`;
    var [reload, setReload] = useState('');

    const revertRejection = (id: string) => {
        setRevertOrderId(id);
        setIsModalOpen(true);
    }

    const submitRevertAnalysisRequest = async (revertOrderId: string) => {
            const token = await Auth.currentSession()
                .then((user) => user.getIdToken()
                    .getJwtToken());
    
            const response = await fetch(REFERRAL_URL_REVERT, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    loanOrderId:revertOrderId
                })
            });
            if (response.ok) {
                toast.success("Reversão realizada com sucesso!");
                return true;
            } else {
                const { message, errors } = await response.json();
                toast.error(message);
                return false;
            }       
        };

    const handleConfirm = useCallback(async () => {
            setIsModalOpen(false);
            setIsLoadingData(true);
            const success = await submitRevertAnalysisRequest(revertOrderId);
            if (success) {
                setReload(`${BASE_REFERRAL_URL}?name=${txtName}&socialId=${txtSocialId}&page=${page}&pageSize=${pageSize}&id=${txtId}&rd=${Math.random().toString(36).substring(2, 15)}`); 
            }
            setIsLoadingData(false);
    }, [revertOrderId, navigate, page, pageSize, fieldSorted, orderSorted]);


    const handleCancel = () => {
        setIsModalOpen(false);
    };



    const renderRows = (rows: ReferraLoanOrderRejected[]) => {
        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={9} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhum registro encontrado...
                        </div>
                    </td>
                </tr>
            );
        }
        return rows.map((row: ReferraLoanOrderRejected, index: number) => (
            <tr key={row.id}
                className={`group ${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-2">{row.id}</td>
                <td className="py-2 px-2 whitespace-nowrap">{row.socialId}</td>
                <td className="py-2 px-2 ">
                    <NewTooltip content={row.farmerName}>
                        <div className="truncate">{row.farmerName}</div>
                    </NewTooltip>
                </td>


                <td className="py-2 px-2  max-w-0">
                    <Tooltip content={row.partnerOriginName}>

                        <div className="truncate">{row.partnerOriginName}</div>
                    </Tooltip>
                </td>


                <td className="py-2 px-2  max-w-0">
                    <Tooltip content={row.guarantorName}>

                        <div className="truncate">{row.guarantorName}</div>
                    </Tooltip>
                </td>

                <td className="py-2 px-2">
                    {
                        row.analysisType === "APPROVE" ? "Aprovado" :
                        row.analysisType === "REJECT_REASON" ? "Reprovado" :
                        row.analysisType === "APPROVE_REASON" ? "Aprovado" : 
                        row.analysisType === "MANUAL_REJECT" ? "Reprovado" :
                        row.analysisType === "REJECT" ? "Reprovado" :""
                    }
                </td>
                <td className="py-2 px-2">
                    {row.statusBeforeAnalysis}
                </td>

                <td className="py-2 px-2">
                    {row.userName}
                </td>

                <td className="py-2 px-2">
                    {row.createdAt}
                </td>

                <td className="py-2 px-4">
                    <div className="opacity-0 group-hover:opacity-100 flex items-center transition-opacity duration-200">
                        <NewTooltip content='Reverter reprovação/cancelamento'>
                            <a 
                                href={`/${row.referralUUID}`}
                                onClick={(e)=>{ e.preventDefault();revertRejection(row.referralUUID)}}
                            >
                            <RiArrowGoBackFill  className='ml-2 text-2xl text-blue-500'  />
                            </a>
                        </NewTooltip>
                    </div>
                </td>
            </tr>
        ));
    }


    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNavigate(page, pageSize, fieldSorted, orderSorted, txtId, txtSocialId,txtName), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtId, txtSocialId, txtName), { replace: true });
    }

    const pageSorted = (field: keyof ReferraLoanOrderRejected | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtId, txtSocialId, txtName), { replace: true });
    }

    const socialIdSearch = (search: string) => {
       navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtId, search, txtName), { replace: true });
    }

    const idSearch = (id: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, id, txtSocialId, txtName), { replace: true });
    }

    const nameSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtId, txtSocialId, search), { replace: true });
    }

    const onFilterClean = () => {
        setTxtId('');
        setTxtName('');
        setTxtSocialId('');
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', '', ''), { replace: true });
    }

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        id: string, socialId: string, name:string ) => {
        return `/referrals/loan-order-analysis/rejected/${page}/${pageSize}/${fieldSorted}/${orderSorted}/i=${id};s=${socialId};n=${name};`;
    }
 
    return (
        <PageList
            pageTitle='Lista de Operações Reprovadas/Canceladas'
            url={reload?reload:url}
            breadcrumb={[{ label: 'Operações Reprovadas', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel='operações'
            defaultBackendHeader={false}
            isLoadingData={isLoadingData}
            tableHeaders={[
                { id: "col-loan-order-analysis-id", title: "#", width: "70px", canSort: true, field: "id" },
                { id: "col-social-id", title: "CPF", width: "120px", canSort: false },
                { id: "col-farmer-name", title: "Nome", width: "180px", canSort: false },
                {
                    id: "col-partner-origin-name",
                    title: "Origem",
                    canSort: false,
                },
                {
                    id: "col-partner-guarantor-name",
                    title: "Avalista",
                    canSort: false
                },
                { id: "col-status", title: "Status", width: "100px", canSort: false },
                { id: "col-previous-status", title: "Status anterior", width: "100px", canSort: false },
                { id: "col-username", title: "Usuário", width: "100px", canSort: false, headerTooltipDescription: "que reprovou/cancelou" },
                { id: "col-created-at", title: "Data", width: "90px", canSort: false, headerTooltipDescription: "Data da reprovação/cancelamento" },
                { id: "col-actions", title: "", width: "50px", canSort: false }
            ]}
        >
            {
                {
                    filterSlot: (
                        <div className="grid grid-cols-4 gap-1">
                            <ASearch
                                id="src-operation"
                                value={txtId}
                                label='Procurar por operação...'
                                onSearch={idSearch}
                                onChange={setTxtId}
                            />
                            <ASearch
                                id="src-name"
                                value={txtName}
                                label='Procurar por nome...'
                                onSearch={nameSearch}
                                onChange={setTxtName}

                            />
                            <PageSearch
                                value={txtSocialId}
                                label='Procurar por CPF...'
                                onSearch={socialIdSearch}
                                onChange={setTxtSocialId}
                                filterCleanVisible={true}
                                filterCleanEnabled={(txtId || txtSocialId || txtName ) ? true : false}
                                configureVisible={false}
                                onFilterClean={onFilterClean}
                            />
                        </div>
                    ),
                    controlSlot: (null),
                    UIComponents:(<ConfirmationModal
                        isOpen={isModalOpen}
                        onCancel={handleCancel}
                        onConfirm={handleConfirm}
                        id="form-order-revert-rejection"
                        title="Reverter reprovação"
                        text="Deseja reverter a reprovação?"
                        />)
                }
            }
        </PageList>
       
    );
};

export default ReferralLoanOrderRejectedListPage;
import React, { InputHTMLAttributes, ReactNode } from 'react';
import InputFrame from '../atoms/InputFrame';

interface TextEditProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    label: string;
    errorMessage?: string;
    className?: string;
    labelClassName?: string;
    prefix?: ReactNode;
    disabled?: boolean;
}

const TextEdit: React.FC<TextEditProps> = ({ prefix, label, errorMessage, className, labelClassName, disabled=false, ...restProps }) => {

    let textColor = "text-gray-900";
    let focusColor = "focus:border-blue-600";

    if (errorMessage) {
        textColor = "text-red-700";
        focusColor = "focus:border-red-600";
    }

    return (
        <InputFrame
            label={label}
            errorMessage={errorMessage}
            className={className}
            prefix={prefix}
            textColor={textColor}
            labelClassName={labelClassName}
        >
            <input
                className={`block py-2.5 px-0 w-full text-sm ${textColor} bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 ${focusColor} peer`}
                placeholder=" "
                {...restProps}
            />
        </InputFrame>
    );
};

export default TextEdit;
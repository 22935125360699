import { FC, useCallback, useEffect, useState } from "react";
import NewTooltip from "../atoms/NewTooltip";
import { HiOutlineTrash } from "react-icons/hi2";
import "./CopyPasteTable.css";
type HeaderTable = {
    label: string;
    field: string;
    required: boolean;
};

type TableResult = {
    line: number;
    result: "success" | "error";
    message: string;
};

interface CopyPasteTableProps {

    fixedHeaders: HeaderTable[];
}

const CopyPasteTable: FC<CopyPasteTableProps> = ({ fixedHeaders = [] }) => {

    const [tableData, setTableData] = useState<string[][]>([]);
    const [draggingColumn, setDraggingColumn] = useState<number | null>(null);
    const [headers, setHeaders] = useState(fixedHeaders);

    const maxColumns = Math.max(headers.length, ...tableData.map(row => row.length));
    const tableHeight = "calc(100vh - 400px)";

    const [errors, setErrors] = useState<TableResult[]>([]);

    useEffect(() => {

        window.addEventListener("CopyPasteTable:clearData", clearData);
        window.addEventListener("CopyPasteTable:setErrors", (event: Event) => setErrors((event as CustomEvent).detail));

        return () => {
            window.removeEventListener("CopyPasteTable:clearData", clearData);
        };

    }, []);

    const clearData = () => {
        setTableData([]);
        setErrors([]);
    };

    const handlePaste = useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const pastedText = event.clipboardData.getData("text");
        const rows = pastedText.split("\n").map(row => row.split("\t"));

        const filteredRows = rows.filter(row => row.some(cell => cell.trim() !== ""));

        setTableData(filteredRows);
    }, []);

    const handleDragStart = (index: number) => {
        setDraggingColumn(index);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
        event.preventDefault();
        if (draggingColumn !== null && draggingColumn !== index) {
            const newHeaders = [...headers];
            const [movedHeader] = newHeaders.splice(draggingColumn, 1);
            newHeaders.splice(index, 0, movedHeader);
            setHeaders(newHeaders);
            setDraggingColumn(index);
        }
    };

    const handleDragEnd = () => {
        setDraggingColumn(null);
        genereateJSonData();
    };

    const removeColumn = (colIndex: number) => {
        setTableData(prevData => prevData.map(row => row.filter((_, i) => i !== colIndex)));
    };

    const removeRow = (rowIndex: number) => {
        setTableData(prevData => prevData.filter((_, i) => i !== rowIndex));
    };

    useEffect(() => {
        genereateJSonData();
    }, [tableData]);

    const genereateJSonData = () => {
        const data = tableData.map(row =>
            headers.reduce((acc, header, colIndex) => {
                acc[header.field] = row[colIndex] || "";
                return acc;
            }, {} as Record<string, string>),
        );

        window.dispatchEvent(new CustomEvent("CopyPasteTable:jsonGenerated", { detail: data }));
    };

    const rowHasError = (row: number) => {
        return errors.some((error: TableResult) => error.line === row && error.result === "error");
    };

    const rowHasSuccess = (row: number) => {
        return errors.some((error: TableResult) => error.line === row && error.result === "success");
    };

    const getCellClass = (rowIndex: number, info: string) => {
        if (rowHasError(rowIndex)) return "bg-red-100";
        if (rowHasSuccess(rowIndex)) return "bg-blue-400";
        return info ? "bg-white" : "bg-gray-100";
    };

    return (
        <>
            {tableData.length == 0 && (
                <textarea
                    className="w-full p-2 border border-gray-300 rounded"
                    rows={5}
                    placeholder="Cole os dados do Excel aqui..."
                    onPaste={handlePaste}
                />
            )}

            {tableData.length > 0 && (

                <div className="overflow-x-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                    <div className="min-w-max">

                        <div className="flex sticky top-0 z-10 bg-white shadow-md">
                            {Array.from({ length: maxColumns }).map((_, colIndex) => (
                                <div
                                    key={colIndex}
                                    className={`flex-1 text-center text-xs p-2 relative cursor-move min-w-[150px] transition-all ${draggingColumn === colIndex ? 'bg-blue-300' : ''}`}
                                    draggable
                                    onDragStart={() => handleDragStart(colIndex)}
                                    onDragOver={(event) => handleDragOver(event, colIndex)}
                                    onDragEnd={handleDragEnd}
                                >
                                    {headers[colIndex] ? (
                                        <div className={`bg-blue-500 text-white p-2 rounded ${headers[colIndex].required ? 'font-bold' : 'font-normal'}`}>
                                            {headers[colIndex].label || "..."}
                                        </div>
                                    ) : (
                                        <div className="text-white p-2 rounded">...</div>
                                    )}
                                    <div className="flex justify-center mt-2">
                                        <button
                                            className="text-red-500 text-lg hover:text-red-700"
                                            onClick={() => removeColumn(colIndex)}
                                        >
                                            <HiOutlineTrash />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        < div className="overflow-y-auto" style={{ maxHeight: tableHeight }}>
                            {tableData.map((row, rowIndex) => (
                                <div key={rowIndex} className="flex items-center">
                                    {Array.from({ length: maxColumns }).map((_, colIndex) => (
                                        <div key={colIndex}
                                            className={`whitespace-nowrap overflow-x-auto scrollbar-thin flex-1 text-center text-xs p-2 border border-collapse min-w-[150px] ${getCellClass(rowIndex, row[colIndex])}`}
                                        >
                                            {row[colIndex] || "-"}
                                        </div>
                                    ))}

                                    <NewTooltip content="Remover linha" alignment="left">
                                        <button
                                            disabled={rowHasSuccess(rowIndex)}
                                            className="text-red-500 ml-2 text-lg disabled:opacity-50"
                                            onClick={() => removeRow(rowIndex)}
                                        >
                                            <HiOutlineTrash />
                                        </button>
                                    </NewTooltip>

                                </div>
                            ))}
                        </div>

                        <div className="p-3 bg-gray-100 text-center font-bold shadow-[inset_0px_5px_15px_-3px_rgba(0,_0,_0,_0.2)]">
                            Linhas: {tableData.length} | Colunas: {tableData[0].length || 0}
                        </div>
                    </div>
                </div>

            )}
        </>
    );

};

export default CopyPasteTable;
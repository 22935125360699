import PresentationModal from "../../organisms/PresentationModal";
import { ReactNode } from "react";

type CommercialConfirmModalProps = {
    visible: boolean;
    handleClose: () => void;
    children: ReactNode;
}
const CommercialConfirmModal = ({ visible, handleClose, children }: CommercialConfirmModalProps) => {

    return (
        <div>{visible &&
            <PresentationModal
                id='commercial-confirm-modal'
                show={true}
                onClose={handleClose}
                header="Confimar Ação"
            >
                <div>
                    {children}
                </div>
            </PresentationModal>

        }</div>
    );

}

export default CommercialConfirmModal;
import { useSignal } from "@preact/signals-react";
import PageForm from "../../templates/PageForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import CurrencyInput from "../../molecules/CurrencyInput";
import DayPicker from "../../molecules/DayPicker";
import SelectTextField from "../../molecules/SelectTextField";
import TextEdit from "../../molecules/TextEdit";
import TextArea from "../../molecules/TextArea";
import { FaMapPin, FaLeaf, FaMapLocationDot, FaMap } from "react-icons/fa6";
import { IoBagOutline } from "react-icons/io5";
import { TbWeight } from "react-icons/tb";
import useFetch from "../../../hooks/useFetch";
import { Auth } from "aws-amplify";
import {NominalValue} from "../../../utils/NominalValue";
import { getErrorMessage, hasErrorMessage, removeErrorField } from '../../../utils/formErrorUtils';
import { ErrorField } from '../../../types/ErrorField';


const LIST_PAGE = "/referrals/loan-order-analysis/0/100/referral.id/ASC";
const FORM_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/loans/form`;
const BACKOFFICE_URL = `${process.env.REACT_APP_BACKOFFICE_URL}`;
const APP_HARVEST_REFERENCE = process.env.REACT_APP_HARVEST_REFERENCE;
const REACT_APP_GEOLOCATION_API_URL = process.env.REACT_APP_GEOLOCATION_API_URL;
const REACT_APP_GEOLOCATION_API_KEY = process.env.REACT_APP_GEOLOCATION_API_KEY;


type AggregationUnitMeasureDTO = {
    plural:string;
    sigla:string;
    singular:string;
}

type Province = {
    initials: string;
    name: string;
    id:number;
}

type City = {
    id: number;
    name: string;
    ibgeCode: string;
    isCapital: string;
    citySize: string;
    province: {
        ibgeCode: string;
        name: string;
        initials: string;
        region: string;
    }
}

type CropType = {
    id: number;
    name: string;
    referency: number;
    description: string;
    qualityStandard: string;
    aggregationUnitMeasureDTO?:AggregationUnitMeasureDTO;
    commodityUnitMeasure?:string;
    cropInformationRegister?:string;
    packagingFormCerc?:string;
    UnitOfMeasurementCerc?:string;
}

type LoanOrderApproved = {
    loanOrderId: string;
    loanOrderAnalysisId: string;
    farmerName: string;
    partnerId: number;
    partnerName: string;
    approvedValue: number;
    nominalValue: number;
    interestRate: number;
    guaranteePercentage: number;
    expirationDate: string;
    cropTypeId: number;
    farmerSocialId: string;
}

type Form = {
    referralId: string;
    farmerId: number;
    partnerId: number;
    approvedValue: number;
    nominalValue: number;
    interestRate: number;
    guaranteePercentage: number;
    expirationDate: string;
    // cultura
    cropTypeId: number;
    // cotação
    quotationDate: string | null;
    quotationValue: number;
    harvestYearReference: string;
    quotationCityId: number;
    quotationProvinceId: number;
    quotationSource: string;

    // commodity
    commodityDescription: string;
    commodityQuality: string;
    commodityAmount: number;
    commodityBagAmount: number;
    commodityReferency: number;
    loanOrder: LoanOrderApproved;
}

const LoanFormPage: React.FC = () => {

    const navigate = useNavigate();
    const { referralId = "" } = useParams();
    let [quotationProvinceId, setQuotationProvinceId] = useState('');
    let [cropTypeId, setCropTypeId]=useState(0);
    
    const loanOrder: LoanOrderApproved = {
        loanOrderId: '',
        loanOrderAnalysisId: '',
        farmerName: '',
        partnerId: 0,
        partnerName: '',
        approvedValue: 0,
        nominalValue: 0,
        interestRate: 0,
        guaranteePercentage: 0,
        expirationDate: '',
        cropTypeId: 0,
        farmerSocialId: ''
    };

    const form = useSignal<Form>({
        referralId,
        farmerId: 0,
        partnerId: 0,
        approvedValue: 0,
        nominalValue: 0,
        interestRate: 0,
        guaranteePercentage: 0,
        expirationDate: '',
        cropTypeId: 0,
        quotationDate: '',
        quotationValue: 0,
        harvestYearReference: '',
        quotationCityId: 0,
        quotationProvinceId: 0,
        quotationSource: 'Agrolink',
        commodityDescription: '',
        commodityQuality: '',
        commodityAmount: 0,
        commodityBagAmount: 0,
        commodityReferency: 0,
        loanOrder
    });

 

    const isLoadingForm = useSignal(false);
    const errorFields = useSignal<ErrorField[]>([]);
    const { data, isLoading, error, isTokenError } = useFetch(`${FORM_URL}/${referralId}`);
    let [CROP_TYPES, setCropTypes] = useState<CropType[]>([]);
    const [CITIES_TO_SELECTION, setCitiesToSelection] = useState<City[]>([]);
    let [selectCropType, setSelectCropType] = useState<CropType>();
    const [provincies, setProvincies] = useState<Province[]>([]);

    const apiVersion = useRef('');

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    function buildReferral(referralId: string): { id: string } {
        return {
          id: referralId
        };
    }
    
    function buildCropType(id:number): { id: number } {
		return {
			id: id
		}
	}

	function buildCity(id: number): { id: number | null } {    
        if (id === undefined || id === 0)
			return { id: null };
		return {
			id: id
		}
	}
    
	function buildProvince(id: number): { id: number | null } {
		if (id === 0)
			return { id: null };
		return {
			id: id
		}
	}

    const fetchProvincies = async () => {
        try {
            const response = await fetch(REACT_APP_GEOLOCATION_API_URL+'/provinces', {
                headers: {
                    'x-api-key': REACT_APP_GEOLOCATION_API_KEY || ''
                }
            });
            const responseData = await response.json();
            setProvincies(responseData.sort((a: Province, b: Province) => {
                return - (a.name < b.name);
            }));

        } catch (error) {

        }
    };

      const fetchQuotationCity = async () => {
        try {
            const province = provincies.find((prov) => prov.id.toString() === quotationProvinceId);
            if (!province) {
                throw new Error('Province not found');
            }
            isLoadingForm.value = true;
            const response = await fetch(REACT_APP_GEOLOCATION_API_URL+'/cities?province_initial=' + province.initials, {
                headers: {
                    'x-api-key': REACT_APP_GEOLOCATION_API_KEY || ''
                }
            });
            const responseData = await response.json();
            setCitiesToSelection(responseData.sort((a: City, b: City) => {
                return - (a.name < b.name);
            }));
            isLoadingForm.value = false;
        } catch (error) {
            isLoadingForm.value = false;
        }
    };

    useEffect(() => {   
        if (quotationProvinceId) {
            fetchQuotationCity();
        }
    },[quotationProvinceId]);

    const onChangeQuotationProvince = (value:string)=>{
        if(value=='' || value=='0' || value==null){
            setQuotationProvinceId('0');
            form.value.quotationProvinceId = 0;
            return;
        }
        setQuotationProvinceId(value);
        form.value.quotationProvinceId = Number(value);
        
    };

    const fetchCropList = async () => {
        try {
            const response = await fetch(`${BACKOFFICE_URL}/services/croptypes/all`);
            if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.statusText}`);
            }
            const data: CropType[] = await response.json();
            CROP_TYPES=data;
            setCropTypes(data);
            setCropTypeId(form.value.loanOrder.cropTypeId);
            onChangeCropType(form.value.loanOrder.cropTypeId.toString());
            isLoadingForm.value = false;
        } catch (err: any) {
            console.log(err.message || 'Erro desconhecido');
        } finally {
           // console.log(false);
        }
    };


    const fetchSelectedCropType= async () => {
        try {
            const response = await fetch(`${BACKOFFICE_URL}/services/croptypes/${cropTypeId}`);
            if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.statusText}`);
            }
            const data: CropType = await response.json();
            setSelectCropType(data);
        } catch (err: any) {
            console.log(err.message || 'Erro desconhecido');
        } finally {
           // console.log(false);
        }
    };

    useEffect(() => {
        if (data) {
            isLoadingForm.value = true;
            apiVersion.current = `${data.apiName} - ${data.apiVersion}`;
            form.value = { ...form.value};
            form.value.loanOrder = data.presenter;
            form.value.loanOrder.nominalValue = NominalValue.progressiveCalculation(data.presenter.approvedValue, data.presenter.interestRate, new Date(), data.presenter.expirationDate);
            form.value.harvestYearReference = APP_HARVEST_REFERENCE || '';
            form.value.loanOrder.guaranteePercentage = data.presenter.guaranteePercentage;
            form.value.loanOrder.loanOrderId = data.presenter.loanOrderId;
    
            form.value.quotationDate = null;
            fetchCropList();
            fetchProvincies();
        }
    }, [data]);

    useEffect(() => {
        if (cropTypeId>0) {
            fetchSelectedCropType();
        }
    }, [cropTypeId]); 
    
    const handleChange = (field: string, value: any) => {
        form.value = { ...form.value, [field]: value };
        if(field=='expirationDate'){
            form.value.loanOrder.expirationDate = value;
            form.value.loanOrder.nominalValue = NominalValue.progressiveCalculation(String(form.value.loanOrder.approvedValue), form.value.loanOrder.interestRate, new Date(), form.value.loanOrder.expirationDate);
        }
        if(field=='quotationCityId'){
            form.value.quotationCityId = value;
        }
        if(field=='quotationDate'){
            form.value.quotationDate = value;
        }
    };

    const handleSubmit = () =>{
        submitNewLoan();
    };

    const submitNewLoan = async () => {
        var jsonLoan = {
            referral: buildReferral(form.value.loanOrder.loanOrderId),
            approvedValue: form.value.loanOrder.approvedValue,
            interestRate: form.value.loanOrder.interestRate,
            expirationDate: form.value.loanOrder.expirationDate,
            guaranteePercentage: form.value.loanOrder.guaranteePercentage,
            cropType: buildCropType(form.value.loanOrder.cropTypeId),
            quotationDate: form.value.quotationDate,
            quotationCity: buildCity(form.value.quotationCityId),// buildCity(form.value.quotationCityId),
            quotationProvince:buildProvince(form.value.quotationProvinceId),//  buildProvince(quotationProvinceId.current),
            commoditySourceQuotation: form.value.quotationSource,
            commodityDescription: form.value.commodityDescription,
            commodityQuality: form.value.commodityQuality,
            commodityPrice: form.value.quotationValue,
            commodityAmount: form.value.commodityAmount,
            referency: form.value.commodityReferency,
            harvestYearReference: form.value.harvestYearReference,
            packagingFormCerc: selectCropType?.packagingFormCerc,
            unitOfMeasurementCerc: selectCropType?.UnitOfMeasurementCerc,
        };
        isLoadingForm.value = true;
        const response = await fetch(BACKOFFICE_URL+"/loan/create", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               loan:jsonLoan
            })
        });
        if (response.ok) {
            isLoadingForm.value = false;
            toast.success(`Emprestimo efetivado com sucesso!`);
            navigate(-1);
        } else {
            isLoadingForm.value = false;
            const { message, validationMessages } = await response.json();
            if(message=='Object Validation Error')
                toast.error("Erro de validação de dados");
            const errors: Array<ErrorField> = []; 
            //esse tratamento é necessário pois o backoffice retorna um array de objetos com os campos fieldName e code que é diferente do tratamento que estamos fazendo
            // nos novos sistemas
            validationMessages.forEach((item: { fieldName: string; code: string; }) => {
                const error: ErrorField = { fieldName: '', message: '' };
                error.fieldName = item.fieldName;
                error.message = item.code;
                errors.push(error);
            });
            errorFields.value = errors ? errors: [];
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const onChangeCropType = (value: string) => {
        form.value.loanOrder.cropTypeId=parseInt(value);
        const cropType = CROP_TYPES.find((crop) => crop.id.toString() === value);
        if (cropType) {
                setSelectCropType(cropType);
                setCropTypeId(form.value.loanOrder.cropTypeId);
                form.value = {
                    ...form.value,
                    cropTypeId: cropType.id,
                    commodityReferency: cropType.referency,
                    commodityDescription: cropType.description ? cropType.description : '',
                    commodityQuality: cropType.qualityStandard ? cropType.qualityStandard : ''
                };
            
        }
    };

    const onQuotationValueChange = (value: number) => {
        const commodityBagAmount = form.value.loanOrder.approvedValue / value;
        const commodityAmount = commodityBagAmount * form.value.commodityReferency;
        form.value = { ...form.value, quotationValue: value, commodityBagAmount, commodityAmount };
    }
    


    return (
        <PageForm
            pageTitle="Efetivar Empréstimo"
            apiVersion={apiVersion.current}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={[{ label: 'Operações aprovadas', url: LIST_PAGE }, { label: 'Efetivar Empréstimo', url: '#' }]}
            controlSlot={(
                    <div className="pt-4 flex justify-end">
                        <button
                            className="px-4 py-2 bg-gray-300 rounded"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </button>
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded ml-2"
                            onClick={handleSubmit}
                        >
                            Efetivar
                        </button>
                    </div>
            )}
        >
       
                    <div className="container mx-auto px-2 py-4">

                        <div className="grid grid-cols-12 gap-4 mt-4">
                            <div className="col-span-2">
                                <TextEdit
                                    label="CPF"
                                    value={form.value.loanOrder.farmerSocialId}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-span-6">
                                <TextEdit
                                    label="Nome do produtor"
                                    value={form.value.loanOrder.farmerName}
                                    disabled={true}
                                />
                            </div>

                            <div className="col-end-13 col-span-1">
                                <TextEdit
                                    label="N. pedido"
                                    value={form.value.loanOrder.loanOrderId}
                                    disabled={true}
                                />
                            </div>

                            <div className="col-start-1 col-span-12">
                                <TextEdit
                                    label="Origem"
                                    value={form.value.loanOrder.partnerName}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <h2 className="text-xl font-semibold mt-4">Empréstimo</h2>
                        <div className="grid grid-cols-10 gap-4 mt-4">
                            <div className="col-start-1 col-span-2">
                                <CurrencyInput
                                    label="Valor aprovado"
                                    prefix="R$"
                                    fractionDigits={2}
                                    disabled={true}
                                    value={form.value.loanOrder.approvedValue}
                                    onChange={(value) => handleChange('approvedValue', value)}
                                />
                            </div>
                            <div className="col-span-2">
                                <CurrencyInput
                                    label="Valor nominal"
                                    prefix="R$"
                                    fractionDigits={2}
                                    value={form.value.loanOrder.nominalValue}
                                    onChange={(value) => handleChange('nominalValue', value)}
                                    disabled={true}
                                />
                            </div>

                            <div className="col-span-2">
                                <CurrencyInput
                                    label="Taxa de remuneração"
                                    prefix="%"
                                    fractionDigits={1}
                                    value={form.value.loanOrder.interestRate}
                                    onChange={(value) => handleChange('interestRate', value)}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <CurrencyInput
                                    label="Aval"
                                    prefix="%"
                                    disabled={true}
                                    value={form.value.loanOrder.guaranteePercentage}
                                    onChange={(value) => handleChange('guaranteePercentage', value)}
                                />
                            </div>

                            <div className="col-span-2">
                                <DayPicker
                                    label="Data de vencimento"
                                    value={form.value.loanOrder.expirationDate}
                                    onChange={(value) => handleChange('expirationDate', value)} 
                                    errorMessage={getErrorMessage(errorFields.value, "loan.expirationDate")}
                                />
                            </div>
                        </div>
                        <h2 className="text-xl font-semibold mt-4">Cotação</h2>
                        <div className="grid grid-cols-12 gap-4 mt-4">

                            <div className="col-span-4">
                                <SelectTextField
                                    id="crop-type-id"
                                    name="cropTypeId"
                                    label="Cultura"
                                    emptyLabel="..."
                                    prefix={<FaLeaf className="text-xl" />}
                                    items={CROP_TYPES.map((crop) => ({ value: crop.id.toString(), label: crop.name }))}
                                    value={form.value.loanOrder.cropTypeId}
                                    errorMessage=""
                                    onChange={e => onChangeCropType(e.target.value)}
                                />
                            </div>

                            <div className="col-span-3">
                                <CurrencyInput
                                    label="Valor da cotação"
                                    prefix="R$"
                                    fractionDigits={2}
                                    value={form.value.quotationValue}
                                    onChange={(value) => onQuotationValueChange(value)}
                                    errorMessage={getErrorMessage(errorFields.value, "loan.commodityPrice")}
                                />
                            </div>
                            <div className="col-span-3">
                                <DayPicker
                                    label="Data da cotação"
                                    value={form.value.quotationDate || null}
                                    onChange={(value) => handleChange('quotationDate', value)}
                                    errorMessage={getErrorMessage(errorFields.value, "loan.quotationDate")}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextEdit
                                    label="Período Safra"
                                    value={form.value.harvestYearReference}
                                    onChange={(e) => handleChange('harvestYearReference', e.target.value)}
                                    errorMessage={getErrorMessage(errorFields.value, "loan.harvestYearReference")}
                                />
                            </div>

                            <div className="col-start-1 col-span-3">
                                <CurrencyInput
                                    label={`Quantidade em ${selectCropType?.aggregationUnitMeasureDTO?.plural || ''}`}
                                    fractionDigits={3}
                                    disabled={true}
                                    value={form.value.commodityBagAmount}
                                    onChange={(value) => handleChange('commodityBagAmount', value)}
                                />
                            </div>

                            <div className="col-span-3">
                                <CurrencyInput
                                    label={`Quantidade de ${selectCropType?.commodityUnitMeasure || ''} por ${selectCropType?.aggregationUnitMeasureDTO?.singular || ''}`} 
                                    prefix={<IoBagOutline className="text-xl" />}
                                    fractionDigits={0}
                                    disabled={true}
                                    value={form.value.commodityReferency}
                                />
                            </div>

                            <div className="col-span-3">
                                <CurrencyInput
                                    label={`Quantidade em ${selectCropType?.commodityUnitMeasure || ''}`}
                                    prefix={<TbWeight className="text-xl" />}
                                    fractionDigits={2}
                                    disabled={true}
                                    value={form.value.commodityAmount}
                                    onChange={(value) => handleChange('commodityAmount', value)}
                                />
                            </div>

                            <div className="col-start-1 col-span-6">
                                <TextEdit
                                    label="Fonte da cotação"
                                    value={form.value.quotationSource}
                                    onChange={(e) => handleChange('quotationSource', e.target.value)} 
                                    errorMessage={getErrorMessage(errorFields.value, "loan.commoditySourceQuotation")}
                                />
                            </div>

                            <div className="col-span-3">
                                <SelectTextField
                                    id="quotation-province-id"
                                    name="quotationProvinceId"
                                    label="UF origem da cotação"
                                    emptyLabel="..."
                                    prefix={<FaMap className="text-xl" />}
                                    value={quotationProvinceId}
                                    items={provincies.map((prov) => ({ value: String(prov.id), label: prov.name }))}
                                    onChange={(e) => onChangeQuotationProvince(e.target.value)}
                                    errorMessage={getErrorMessage(errorFields.value, "loan.quotationProvince")}
                                />
                            </div>

                            <div className="col-span-3">
                                <SelectTextField
                                    id="quotation-city-id"
                                    name="quotationCityId"
                                    label="Cidade origem da cotação"
                                    emptyLabel="..."
                                    prefix={<FaMapLocationDot className="text-xl" />}
                                    value={form.value.quotationCityId}
                                    items={CITIES_TO_SELECTION.map((city) => ({ value: String(city.id), label: city.name }))}
                                    onChange={(e) => handleChange('quotationCityId', e.target.value)}
                                    errorMessage={getErrorMessage(errorFields.value, "loan.quotationCity")}
                                />
                            </div>

                            <div className="col-span-6">
                                <TextArea
                                    id="commodity-description"
                                    rows={4}
                                    label="Descrição do produto"
                                    value={form.value.commodityDescription}
                                    onChange={(e) => handleChange('commodityDescription', e.target.value)}
                                    errorMessage={getErrorMessage(errorFields.value, "loan.commodityDescription")}
                                />
                            </div>

                            <div className="col-span-6">
                                <TextArea
                                    id="commodity-quality"
                                    rows={6}
                                    label="Características/qualidades do produto"
                                    value={form.value.commodityQuality}
                                    onChange={(e) => handleChange('commodityQuality', e.target.value)}
                                    errorMessage={getErrorMessage(errorFields.value, "loan.commodityQuality")}
                                />
                            </div>
                        </div>

                    </div>
                
        </PageForm >
    );

};

export default LoanFormPage;